
import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js"
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import { connect } from "react-redux";
import { compose } from "redux";

//components
import BugReport from "@material-ui/icons/BugReport";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { getOrders, clearCursorListener, getMarkets,  getSingleOrders, listenForProductUpdates} from "Reducers/Actions/loadingData";
import Orders from "../pages/Orders/OrdersTypes/inProgress";
import LoadScreen from "../pages/Orders/OrdersTypes/loadScreen"
// import moment from 'moment'

import { bugs, website, server } from "variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Dashboard } from "@material-ui/icons";
// import market from "views/Products/market";

const useStyles = makeStyles(styles);


export default function AdminDashboard(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    progress: true,
    completed: false,
    cancelled: false,
    total: false
  })

  //const [loaded, setLoaded] = useState(false);
  const[listener, setListener] = useState({});
  const [load, setLoad] = useState(false)
  //const[orders, setOrders] = useState([]);
  const[inProgressOrder, setInProgressOrder] = useState([]);
  const[completedOrders, setCompletedOrders] = useState([]);
  const[cancelledOrders, setCancelledOrders] = useState([]);
  const[totalOrders, setTotalOrders] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [marketIds, setMarketIds] = useState([]);


  console.log(inProgressOrder)

  const orders = useSelector(state => state.dataState.orders)
  const requireUpdate = useSelector((state) => state.dataState.update);
  const markets = useSelector(state => state.dataState.markets)
  const vendorIds = useSelector(state => state.dataState.vendorIds)
  const ordersLength = orders.length;
  const marketLength = markets.length;
  //console.log("Orders length outside effect:", ordersLength);
  console.log(orders);



  let data;

  useEffect(() => {
   
      dispatch(getOrders());
      dispatch(getMarkets())
    
   
      loadData();
      setLoad(true);
    return function cleanup (){
    clearCursorListener()
    }

  }, [ ordersLength])


  const loadData = () => {
    //console.log("Setting data...", orders);
    setStatus({
      progress: true,
      completed: false,
      cancelled: false,
      total: false
    });
    setStatCompleted();
    setStatProgress();
    setStatCancelled();
    setStatTotal();
  }

  const handleRowClick = (orderId, customerId, totalAmount, deliveryOption, orderTimestamp) => {
    props.history.push(`/app/orders/${orderId}/${customerId}/${totalAmount}/${deliveryOption}/${orderTimestamp}`)
    // if(markets){
    //     markets && markets.map(market => {
    //       setAgentId(market.agent_id);
    //       setMarketIds(...marketIds, market.market_id)
    //       dispatch(getSingleOrders(market.agent_id, market.market_id, orderId));
        
    //     });
        
      

      
    
    //     // return <LoadScreen orderId={orderId} />
    // }
  }

  const setStatProgress = () => {
    setInProgressOrder([]);
    orders && orders.map(order => {
      if (order.status === "active" || order.status === "order_processed" || order.status === "order_accepted") {
        setInProgressOrder((list) => [...list, order.order_id]);
       
      }
    })

    
    return inProgressOrder.length;
  }


  const setStatCompleted = () => {
    setCompletedOrders([]);
    orders && orders.map(order => {
      if (order.status === "order_delivered") {
        setCompletedOrders((list) => [...list, order.order_id]);
      }
    })
    return completedOrders.length;
  }

  const setStatCancelled = () => {
    setCancelledOrders([]);
    orders && orders.map(order => {
      if (order.status === "order_cancelled") {
        setCancelledOrders((list) => [...list, order.order_id]);
      }
    })
    return cancelledOrders.length; 
  }

  const setStatTotal = () => {
    setTotalOrders([]);
    orders && orders.map((order) => setTotalOrders((list) => [...list, order.order_id]));
    return totalOrders.length;
  }

  if (load) {
    return(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>

            <Card onClick={(e) => setStatus({ completed: false, progress: true, cancelled: false, total: false})} >
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Store />
                </CardIcon>

                <p className={classes.cardCategory} style={{ color: "black" }}>Orders In Progress</p>
                <h3 className={classes.cardTitle}> {inProgressOrder.length}</h3>

              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>

          </GridItem>
          <GridItem xs={12} sm={6} md={4}>

            <Card onClick={(e) => setStatus({ completed: true, progress: false, cancelled: false, total: false })} >
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <DateRange />
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}>Orders Completed</p>
                <h3 className={classes.cardTitle}>{completedOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>
              </CardFooter>
            </Card>

          </GridItem>
          {/* <GridItem xs={12} sm={6} md={3}>
            <Card onClick={(e) => setStatus({ cancelled: true, progress: false, completed: false, total: false })}>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  < BugReport />
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}>Orders<br /> Cancelled</p>
                <h3 className={classes.cardTitle}>{cancelledOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>
          </GridItem> */}
          <GridItem xs={12} sm={6} md={4}>
            <Card onClick={(e) => setStatus({ total: true, completed: false, progress: false, cancelled: false })}>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon>info_line</Icon>
                </CardIcon>
                <p className={classes.cardCategory} style={{ color: "black" }}> Total Orders</p>
                <h3 className={classes.cardTitle}>{totalOrders.length}</h3>
              </CardHeader>
              <CardFooter stats>

              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
             {
              status.progress ? <Orders  orders={inProgressOrder} type={"progress"} marketIds={marketIds} handleClick={handleRowClick} vendorIds={vendorIds} agentId={agentId} load={load}/> :
               (status.completed) ? <Orders  orders={completedOrders} type={"completed"}  marketIds={marketIds} handleClick={handleRowClick} vendorIds={vendorIds} agentId={agentId} load={load}/> :
                (status.cancelled) ? <Orders orders={cancelledOrders} type={"cancelled"}  marketIds={marketIds} handleClick={handleRowClick} vendorIds={vendorIds} agentId={agentId} load={load}/> : 
                <Orders  orders={totalOrders} type={"total"}  marketIds={marketIds} handleClick={handleRowClick} vendorIds={vendorIds} agentId={agentId} load={load}/>
            } 
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    return(
      <div>
        Loading...
      </div>
    );
  }
}





































// import React, {Component } from "react";
// // react plugin for creating charts
// import ChartistGraph from "react-chartist";
// // @material-ui/core
// // import { makeStyles } from "@material-ui/core/styles";
// import { withStyles } from "@material-ui/styles";
// import Icon from "@material-ui/core/Icon";
// // @material-ui/icons
// import Store from "@material-ui/icons/Store";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// // core components
// import GridItem from "components/Grid/GridItem.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// // import Tasks from "components/Tasks/Tasks.js";
// // import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
// import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
// import { connect } from 'react-redux';
// import { compose } from "redux";

// // import { bugs, website, server } from "variables/general.js";

// // import {
// //   dailySalesChart,
// //   emailsSubscriptionChart,
// //   completedTasksChart
// // } from "variables/charts.js";

// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// // const useStyles = makeStyles(styles);

//  class Dashboard extends Component {
//    constructor(props){
//      super(props)
//      this.state ={}
//    }
//    render(){
//   const {classes} = this.props;

//   return (
//     <div>
//       <GridContainer>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card>
//             <CardHeader color="warning" stats icon>
//               <CardIcon color="warning">
//                <Store />
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}>Orders In Progress</p>
//               <h3 className={classes.cardTitle}> 45000    </h3>
//             </CardHeader>
//             <CardFooter stats>
              
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card>
//             <CardHeader color="success" stats icon>
//               <CardIcon color="success">
//                 <DateRange />
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}>Orders Completed</p>
//               <h3 className={classes.cardTitle}>3,4245</h3>
//             </CardHeader>
//             <CardFooter stats>
//              </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card>
//             <CardHeader color="danger" stats icon>
//               <CardIcon color="danger">
//                 < BugReport />
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}>Orders<br/> Cancelled</p>
//               <h3 className={classes.cardTitle}>75</h3>
//             </CardHeader>
//             <CardFooter stats>
              
//             </CardFooter>
//           </Card>
//         </GridItem>
//         <GridItem xs={12} sm={6} md={3}>
//         <Card>
//             <CardHeader color="info" stats icon>
//               <CardIcon color="info">
//                 <Icon>info_line</Icon>
//               </CardIcon>
//               <p className={classes.cardCategory} style={{color: "black"}}> Total <br/> Orders</p>
//               <h3 className={classes.cardTitle}>75,000</h3>
//             </CardHeader>
//             <CardFooter stats>
             
//             </CardFooter>
//           </Card>
//         </GridItem>
     
//       </GridContainer>
    
//       <GridContainer>
//         <GridItem xs={12} sm={12} md={12}>
//         <Card>
//             <CardHeader color="warning">
//               <h4 className={classes.cardTitleWhite}>Orders Stats</h4>
//               <p className={classes.cardCategoryWhite}>
//                 Orders as  on 15th June, 2020
//               </p>
//             </CardHeader>
//             <CardBody>
//               <Table
//                 tableHeaderColor="warning"
//                 tableHead={["ORDER ID", "VENDOR", "AMOUNT", "MARKET", "AGENT"]}
//                 tableData={[
//                   ["301234", "Dakota Rice", "$36,738", "Oshodi Market", "Muhamed Alie"],
//                   ["401356", "Minerva Hooper", "$23,789", "Ijora Market", "Taofeek Wally"],
//                   ["56789", "Sage Rodriguez", "$56,142",  "Ajah Market", "Kunle Bash"],
//                   ["978567", "Philip Chaney", "$38,735", "Aswani Market", "Taiwo Thomas"]
//                 ]}
//               />
//             </CardBody>
        
//           </Card>
//         </GridItem>
//       </GridContainer>
//     </div>
//   );
//               }
// }

// const mapStateToProps = (state)=>{

//   return{
//     users: state.firestore.ordered.users,
//     agents: state.firestore.ordered.agents
//   }
  
// }


// export default compose(connect(mapStateToProps),
// firestoreConnect((props)=>[
//   {collection: 'users'},
//   {collection: 'agents'}
// ]),withStyles(styles))(Dashboard)


