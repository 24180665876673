import totalFirebaseApp from '../../Config/config';
import {GET_AGENTS,  LOADING_DATA, DELETE_AGENT, POST_AGENT, GET_AGENT, GET_MARKETS, POST_AGENT_ERROR, SET_ERRORS, CLEAR_ERRORS, SET_IMAGE_URL, SET_IMAGE_ERROR} from '../Redux/type';

let firebase = totalFirebaseApp.agentAuth;
const storage = totalFirebaseApp.agentStorage;

export const agentAction = (credentials) => async (dispatch) => {
  console.log("Image:", credentials.image);
  let userId;

  firebase.createUserWithEmailAndPassword(credentials.email, credentials.password).then((doc) => {
    userId = doc.user.uid;

    return userId;
  }).then((userId) => {
    return storage.ref().child(`agents/profilePictures/${userId}/image.jpg`).put(credentials.image, {contentType: credentials.image.type});
  }).then((uploadResult) => {
    return uploadResult.ref.getDownloadURL();
  }).then((url) => {
    return totalFirebaseApp.agentFirestore.collection("users").doc(userId).set({
      user_id: userId,
      user_name: credentials.name,
      user_email: credentials.email,
      user_phone_number: credentials.phone,
      user_avatar: url,
      creation_date: credentials.createdAt,
      market: credentials.market.name,
      market_id: credentials.market.market_id,
      total_orders: 0,
      reassigned_orders: 0,
      status: "active",
      market_status: "Not in market",
    });
  }).then(() => {
    dispatch({
      type: POST_AGENT,
      payload: { id: userId, ...credentials },
    })
  }).catch((err) => {
    //alert(err)
    dispatch({
      type: POST_AGENT_ERROR,
      payload: err.message,
    })
  });
}











