import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
// @material-ui/core components
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { white } from "material-ui/styles/colors";
import HeaderLinks from "components/Header/headerlinkRight";
import {getSingleMarket, getAgents, updateMarketAgent} from "Reducers/Actions/loadingData"

import avatar from "assets/img/faces/marc.jpg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  const classes = useStyles();
    const market = useSelector(state => state.dataState.market);
    const agents = useSelector(state => state.dataState.agents)
    const dispatch = useDispatch();

    const [selectedAgent, setSelectedAgent] = useState("");
    const [selectedPhone, setSelectedPhone] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedValueId, setSelectedValueId] = useState("");
    const [open, setOpen] = useState(false);

    let id = props.match.params.id;

    useEffect(() => {
        dispatch(getSingleMarket(id))
        dispatch(getAgents())  
    }, [id])

    const handleClickOpen = () => {
      setOpen(true);
    };
    
    const handleClose = (value) => {
      setOpen(false)
      if (!value) return;
      setSelectedAgent(value.user_name)
      setSelectedPhone(value.user_phone_number)
      setSelectedImage(value.user_avatar)
      setSelectedValueId(value.user_id)
     }; 
 
    //   const handleListItemClick = (value) => {
    //    handleClose(value);
    //  };

if(market){
  return (
    <div>
      <Header
        absolute
        color="black"
        brand="Price n' Pay"
        rightLinks={<HeaderLinks />}
        {...props.rest}
      />
      <GridContainer style={{marginTop:"100px"}} justify={"center"}>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Market</h4>
              <p className={classes.cardCategoryWhite}>market Details</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                   <GridItem xs={12} sm={12} md={4}>
                   Market ID
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.market_id}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   Market Name:
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.name}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   LOCATION COORDINATE
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.coord ? market.coord.latitude : ''}, {market.coord ? market.coord.longitude : ''}</p>
                   </GridItem>
                  </GridContainer>
                  <GridContainer>
                   <GridItem xs={12} sm={12} md={8}>
                   Description
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.description}</p>
                   </GridItem>
                   {/* <GridItem xs={12} sm={12} md={4}>
                   No Of VENDORS:
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.vendor}</p>
                   </GridItem> */}
                   <GridItem xs={12} sm={12} md={4}>
                   LOCATION
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{market.location}</p>
                   </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
            {/* <CardFooter>
              <Button color="info">Agent in Charge</Button>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={selectedImage? selectedImage: market.agent_image_url} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile style={{margin:"auto"}}>
              <p className={classes.cardCategory} style={{color: "#FE5E00", textAlign: "center"}}>AGENT IN CHARGE</p>
              <p style={{textAlign: "center"}}>{selectedAgent?selectedAgent: market.agent_name}</p>
              <p className={classes.description} style={{textAlign: "center"}}>
               {selectedPhone? selectedPhone:market.agent_phone_number}
              </p>
              
              <Button color="warning" style={{textAlign: "center"}} round onClick={handleClickOpen}>
                Change Agent
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <SimpleDialog selectedAgent={selectedAgent} selectedPhone={selectedPhone} 
      selectedImage={selectedImage} open={open} onClose={handleClose}  agents={agents} id={id} dispatch={dispatch} selectedValueId={selectedValueId} market={market}/>
    </div>
  );
            }else{
              return <div>
                Loading....
              </div>
            }
}

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedAgent, selectedPhone,selectedImage, open,  agents, id, selectedValueId, dispatch, market} = props;

  const handleClose = () => {
    onClose(selectedAgent);
    onClose(selectedPhone)
    onClose(selectedImage)
    // dispatch(updateMarketAgent(id, market.name, selectedValueId))
  };

  const handleListItemClick = (value) => {
    onClose(value);
    if(value && market){
      dispatch(updateMarketAgent(id, market.name, value))
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select New Agent For Market</DialogTitle>
      {agents != null ? <List>
                  {agents.map((item) => (
                      <ListItem button onClick={() => handleListItemClick(item)} key={item.user_id}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            <img src={item.user_avatar} alt="..." style={{ height: "150px", width: "150px", padding: "10px" }} />
                          </Avatar>
                        </ListItemAvatar>
                          <ListItemText primary={item.user_name}/>
                          {/* <ListItemText primary={item.user_phone_number}/> */}
                       </ListItem>
                  ))}
               </List> : <div>Loading...</div>}
    </Dialog>
  );
}







  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };