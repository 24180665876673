/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput";
import { connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import {  LogOut } from "../../Reducers/Actions/loginAction"

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      
      <ListItem className={classes.listItem}>
      <Button
  color="info"
  className={classes.navLink}
>
<Link to={"/admin/dashboard"}><CloudDownload className={classes.icons} /> Dashboard</Link>
</Button>
      </ListItem>
  
    </List>
  );
}




const mapStateToProps = (state)=>{

  
 return { 
  profile: state.firebase.profile,
  authenticated: state.authState.authenticated
 }
}



const mapDispatchToProps = (dispatch)=> {
  return {
    LogOut: () => {
        dispatch( LogOut ())
      }
}

}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks)



















// <ListItem className={classes.listItem}>
//         <Button
//          href="#"
//         //   href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
//           color="transparent"
//           target="_blank"
//           className={classes.navLink}
//         >
//           <CloudDownload className={classes.icons} /> Download
//         </Button>
//       </ListItem>
//       <ListItem className={classes.listItem}>
//         {/*<Tooltip title="Delete">
//           <IconButton aria-label="Delete">
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>*/}
//         <Tooltip
//           id="instagram-twitter"
//           title="Follow us on twitter"
//           placement={window.innerWidth > 959 ? "top" : "left"}
//           classes={{ tooltip: classes.tooltip }}
//         >
//           <Button
//             // href="https://twitter.com/CreativeTim?ref=creativetim"
//              href="#"
//             target="_blank"
//             color="transparent"
//             className={classes.navLink}
//           >
//             <i className={classes.socialIcons + " fab fa-twitter"} />
//           </Button>
//         </Tooltip>
//       </ListItem>