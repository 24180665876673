import {
  GET_AGENTS,
  LOADING_DATA,
  DELETE_AGENT,
  POST_AGENT,
  GET_SINGLE_AGENT,
  GET_MARKETS,
  POST_AGENT_ERROR,
  SET_ERRORS,
  CLEAR_ERRORS,
  GET_SINGLE_USER,
  CREATE_DISPATCHER,
  CREATE_DISPATCHER_ERROR,
  GET_USERS,
  USER_UPDATE,
  GET_ALL_ORDERS,
  GET_ORDER_IN_PROGRESS_DETAILS,
  GET_COMPLETED_ORDER_DETAILS,
  GET_CANCENCELLED_ORDER_DETAILS,
  GET_TOTAL_ORDER_DETAILS,
  GET_SINGLE_ORDER,
  GET_CUSTOMER,
  LISTEN_FOR_PRODUCT_UPDATES,
  GET_DISPATCHERS,
  GET_ALL_PRODUCTS,
  GET_SINGLE_DISPATCHER,
  GET_SINGLE_MARKET,
  GET_VENDORS,
  GET_SINGLE_VENDOR,
  GET_VENDOR_WITH_ORDER,
  GET_VENDOR_WITH_PRODUCT,
  UPDATE_AGENT,
  AGENT_WITH_ORDERS,
  DELETE_VENDOR,
  DELETE_DISPATCHER,
  UPDATE_DISPATCHER,
  MARKET_AGENT_UPDATE,
  ADD_NEW_MARKET,
  ADD_NEW_PRODUCT,
  GET_SINGLE_PRODUCT,
  GET_PRICEPAY_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  STATUS_CHANGE,
  GET_PENDING_VERIFICATIONS,
  GET_PENDING_PRODUCT,
  GET_ORDER_MARKETS,
  BULK_PAYMENT, GET_PENDING_DISBURSEMENT, GET_DISBURSEMENT_HISTORY
} from "./type";

const initialState = {
  agent: {},
  agents: [],
  users: [],
  user: {},
  dispatchers: [],
  dispatcher: {},
  markets: [],
  market: {},
  errors: null,
  vendorIds: [],
  message: "",
  orders: [],
  customer: {},
  products: {},
  vendors: [],
  vendor: {},
  pricenpayProduct:[],
  status: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: action.payload,
        showLoading: action.payload.showLoading,
        status: action.payload.status
      };
    case GET_AGENTS:
      return {
        ...state,
        agents: action.payload,
        loading: true,
        update: Math.random()
      };

    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: true,
      };
    case GET_MARKETS:
      return {
        ...state,
        markets: action.payload,
        loading: true,
        update: Math.random()
      };
    case GET_SINGLE_ORDER:
      return {
        ...state,
        vendorIds: action.payload,
        loading: true,
      };

    case UPDATE_AGENT:
      return {
        ...state,
        agent: action.payload,
      };

    case AGENT_WITH_ORDERS:
      return {
        ...state,
        agentOrders: action.payload,
      };

    case DELETE_AGENT:
      // let index = state.agents.findIndex(
      //   (agent) => agent.id === action.payload
      // );
      // state.agents.splice(index, 1);
      return {
        ...state,
        message: "Agent deleted!",
      };

    case DELETE_VENDOR:
      return {
        ...state,
        message: `Vendor deleted!, id:${action.payload} `,
      };
    case DELETE_DISPATCHER:
      return {
        ...state,
        message: `Dispatcher deleted!, id${action.payload}`,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case STATUS_CHANGE:
      return{
        ...state,
        message: action.payload
      }
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_PRICEPAY_PRODUCTS:
      return{
        ...state,
        pricenpayProduct: action.payload
      }
    case POST_AGENT:
      return {
        ...state,
        //agents: [...state.agents, action.payload],
        message: "Agent successfully created!",
        loading: false,
        status: 'success',
        showLoading: false
      };

    case CREATE_DISPATCHER:
      return {
        ...state,
        dispatchers: [...state.dispatchers, action.payload],
        message: "Dispatcher successfully created!",
        showLoading: false,
        status: 'success'
      };
    case CREATE_DISPATCHER_ERROR:
      return {
        ...state,
        message: action.payload,
        errors: action.payload,
        showLoading: false
      };

    case GET_DISPATCHERS:
      return {
        ...state,
        dispatchers: action.payload,
        update: Math.random()
      };
    case GET_SINGLE_DISPATCHER:
      return {
        ...state,
        dispatcher: action.payload,
      };
    case UPDATE_DISPATCHER:
      return {
        ...state,
        dispatcher: action.payload,
      };
    case POST_AGENT_ERROR:
      return {
        ...state,
        agent: [],
        errors: action.payload,
        message: action.payload,
        loading: false,
        status: 'error',
        showLoading: false
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_SINGLE_AGENT:
      return {
        ...state,
        agent: action.payload,
        loading: true,
      };
    
      case GET_SINGLE_PRODUCT:
        return{
          ...state,
          product: action.payload
        }

    case GET_SINGLE_USER:
      return {
        ...state,
        user: action.payload,
        loading: true,
      };
    case USER_UPDATE:
      return {
        ...state,
        user: action.payload,
        loading: true,
      };

    case UPDATE_PRODUCT:
      return{
        ...state,
        message: action.payload
      }

    case DELETE_PRODUCT:
      return{
        ...state,
        message: action.payload
      }
    case GET_ORDER_IN_PROGRESS_DETAILS:
      return {
        ...state,
        ordersInProgress: action.payload,
        loading: false,
      };

    case GET_COMPLETED_ORDER_DETAILS:
      return {
        ...state,
        completedOrders: action.payload,
        loading: false,
      };

    case GET_CANCENCELLED_ORDER_DETAILS:
      return {
        ...state,
        cancelledOrders: action.payload,
        loading: false,
      };

    case GET_VENDOR_WITH_PRODUCT:
      return {
        ...state,
        vendProducts: action.payload,
      };

    case GET_VENDOR_WITH_ORDER:
      return {
        ...state,
        vendOrders: action.payload,
      };
    case GET_TOTAL_ORDER_DETAILS:
      return {
        ...state,
        allOrders: action.payload,
        loading: false,
      };
    case GET_SINGLE_MARKET:
      return {
        ...state,
        market: action.payload,
      };

    case GET_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: true,
      };
    case GET_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };

    case GET_SINGLE_VENDOR:
      return {
        ...state,
        vendor: action.payload,
      };
    case ADD_NEW_PRODUCT:
      return {
        ...state,
        message: action.payload,
      };
    case LISTEN_FOR_PRODUCT_UPDATES + action.id:
      return {
        ...state,
        loading: false,
        [action.id]: action.payload,
        products: action.payload,
        update: Math.random(),
      };
    case MARKET_AGENT_UPDATE:
      return {
        ...state,
        market_agent_update: action.payload,
      };
    case GET_PENDING_VERIFICATIONS:
      return {
        ...state,
        pendingVerifications: action.payload,
        update: Math.random()
      }
    case GET_PENDING_PRODUCT:
      return {
        ...state,
        pendingProduct: action.payload
      }
    case GET_ORDER_MARKETS:
      return {
        ...state,
        orderMarkets: action.payload
      }
    case BULK_PAYMENT:
      return {
        ...state,
        paymentAuthorization: action.payload,
        message: action.message
      }
    case GET_PENDING_DISBURSEMENT:
      return {
        ...state,
        disbursements: action.payload,
        update: Math.random()
      }
    case GET_DISBURSEMENT_HISTORY:
      return {
        ...state,
        disbursementHistory: action.payload
      }

    default:
      return state;
  }
}
