import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomInput from "components/CustomInput/CustomInput.js";
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { Button } from "@material-ui/core";
import { formatMoney } from "utils";
import { makeBulkPayment, getPendingDisbursements, unsubscribeDisbursementListener } from "Reducers/Actions/loadingData";
import moment from 'moment';

const useStyles = makeStyles(styles);

export default function PaymentsTable(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const disbursements = useSelector(state => state.dataState.disbursements);
  const requireUpdate = useSelector(state => state.dataState.update);
  let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPendingDisbursements());
        getTotal();
        return () => unsubscribeDisbursementListener();
    }, [requireUpdate])

  const handleClick = (id) =>{
    console.log("you click me")
    //return props.history.push(`/app/payments/${id}`)
  
    }

    const getTotal = () => {
        let amount = 0;
        if (disbursements) {
            for (let dis of disbursements) {
                amount += dis.amount;
            }
        }
        setTotalAmount(amount);
    }

const payVendors = (e) => {
    e.preventDefault();
    setOpen(true);
}

const handleClose = (value) => {
  setOpen(false);
  console.log("Closing value:", value);
};
  const {tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
        <Card>
            <CardHeader color="warning">
            <h4>Pending Payments</h4>
            </CardHeader>
       
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.warningTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Vendor Id
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                           Vendor Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Last Update
                  
                  </TableCell>
                  
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Amount
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {disbursements && disbursements.map((row) => {
            return (
              <TableRow key={row.vendor_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(row.vendor_id)}>
                <TableCell className={classes.tableCell} key={row.vendor_id}>
                    {row.vendor_id}
                </TableCell>
                <TableCell className={classes.tableCell} key={row.vendor_name}>
                    {row.vendor_name}
                </TableCell>
                <TableCell className={classes.tableCell} key={row.timestamp}>
                    {moment(row.timestamp).format('LL')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {"₦"}{formatMoney(row.amount)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </Card>
      <Card>
        <GridContainer>
            <GridItem xs={12} sm={6} md={9}>
            <h4 style={{paddingLeft:"20px"}}>TOTAL: <span style={{color:"#FE5E00"}}>{"₦"}{formatMoney(totalAmount)}</span></h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height:"100%"}}>
                <Button style={{backgroundColor:"#FE5E00",color:"white"}} disabled={totalAmount === 0} onClick={payVendors}>PAY ALL VENDORS</Button>
            </div>
        {/* <Button color="warning"  onClick={handleClickOpen }>Add Market</Button> */}
            </GridItem>
        </GridContainer>
      </Card>
      <SimpleDialog open={open} onClose={handleClose} />
    </div>
  );
}

PaymentsTable.defaultProps = {
  tableHeaderColor: "gray"
};

PaymentsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};

function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
    const dispatch = useDispatch();
    const paymentAuthorization = useSelector(state => state.dataState.paymentAuthorization);
    const message = useSelector(state => state.dataState.message);
    const [state, setState] = useState({
        password: ""
      });

    // useEffect(() => {
    //     dispatch(makeBulkPayment());
    //     return () => unsubscribePaymentStatusListener();
    // }, [])

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const authorize = () => {
        let data = {
            password: state.password
        }
        dispatch(makeBulkPayment(data));
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" aria-describedby="alert-dialog-description" open={open}>
            {(!paymentAuthorization || paymentAuthorization === "loading") && (
                <DialogTitle id="simple-dialog-title" style={{color:"#FE5E00"}}>Authorization Required!</DialogTitle>
            )}
            {paymentAuthorization === "makingPayment" && (
                <DialogTitle id="simple-dialog-title" style={{color:"#FE5E00"}}>Vendor Bulk Payment!</DialogTitle>
            )}
            {paymentAuthorization === "completed" && (
                <DialogTitle id="simple-dialog-title" style={{color:"#FE5E00"}}>Bulk Payment Successful!</DialogTitle>
            )}
            <DialogContent>
                {(!paymentAuthorization || paymentAuthorization === "loading") && (
                    <DialogContentText id="alert-dialog-description">
                        An admin password is required to perform this action.
                    </DialogContentText>
                )}
                {paymentAuthorization === "makingPayment" && (
                    <DialogContentText id="alert-dialog-description">
                        Making bulk payments to vendors...
                    </DialogContentText>
                )}
                {paymentAuthorization === "completed" && (
                    <DialogContentText id="alert-dialog-description">
                        All payments made to vendors were successful.
                    </DialogContentText>
                )}
                {!paymentAuthorization && (
                    <CustomInput
                    labelText="Enter Password"
                    id="password"
                    name="password"
                    formControlProps={{
                    fullWidth: true,
                    }}
                    error={message}
                    inputProps={{
                    disabled: false,
                    type: "password",
                    value: state.password,
                    onChange: e => setState({ ...state, password: e.target.value }),
                    }}
                />
                )}
                {message && (<p style={{color:"red"}}>{message}</p>)}
            </DialogContent>
            <DialogActions>
                {(paymentAuthorization === "loading" || paymentAuthorization === "makingPayment") ? (
                    <CircularProgress color="warning"/>
                ) : (paymentAuthorization === "completed") ? (
                    <Button style={{backgroundColor:"#FE5E00",color:"white"}} onClick={handleClose} autoFocus>
                        Close
                    </Button>
                ) : (
                    <Button style={{backgroundColor:"#FE5E00",color:"white"}} onClick={authorize} autoFocus>
                        Submit
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    //selectedValue: PropTypes.string.isRequired,
};