import React, {useEffect, useState, Component} from "react";
import {useSelector, useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import {getSingleUser, updateUser} from 'Reducers/Actions/loadingData';
import Form from "./form"

import avatar from "assets/img/faces/marc.jpg";



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);


export default function UserUpDate(props){
    const dispatch = useDispatch();
    const classes = useStyles();
    const id = props.match.params.id;
    const user = useSelector(state => state.dataState.user)
    const serverMessage = useSelector(state => state.dataState.message)
    const [load, setLoad] = useState(false);
    const [message, setMessage]= useState(null)
    const [state, setState] = useState({
        name: user.name,
        email: user.email,
        phone_number: user.phone_number,
        user_id:user.user_id,
        credit: user.credit

    })
 
      const   handleButton = ()=>{
            setTimeout(() => {
                setMessage(false)
            }, 3000);
        }


        useEffect(() => {

            let id = props.match.params.id;
            dispatch(getSingleUser(id))
            // return () => {
            //     cleanup
            // }
        }, [id])

      
const handleName = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      [event.target.name]: value,
    });
  };

  const handleSubmit = (e)=>{
        e.preventDefault()
        setMessage(serverMessage)
        let id= props.match.params.id;
        const userData = {
            user_id: state.user_id,
            name: state.name,
            email:state.email,
            phone_number: state.phone_number,
            credit: state.credit
        }

        console.log(userData)

    dispatch(updateUser(id, userData))
    }
     
    const { ...rest} = props;
    console.log(state)
    return (
        <div>
            <Form user={state.user} handleName={handleName} handleSubmit={handleSubmit} {...props} id={id}  handleButton={handleButton} message={message} classes={classes} state={state} setState={setState}/>
</div>
    )
  
}






