

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { getDisbursementHistory } from "Reducers/Actions/loadingData";
import moment from 'moment';
import { formatMoney } from 'utils';


const useStyles = makeStyles(styles);

export default function PaymentHistoryTable(props) {
  const classes = useStyles();
  const disbursementHistory = useSelector(state => state.dataState.disbursementHistory);
  let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDisbursementHistory());
    }, []);

  const handleClick = (id) =>{
    console.log("you click me")
    return props.history.push(`/app/payments/${id}`)
  
}
  const {tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
        <Card>
            <CardHeader color="primary"> <h4>Bulk Payments History</h4></CardHeader>
       
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.primaryTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Disbursement Id
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                           Total Vendors Paid
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Payment Date
                  
                  </TableCell>
                  
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Total Amount
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {disbursementHistory && disbursementHistory.map((row) => {
            return (
              <TableRow key={row.disbursement_id} className={classes.tableBodyRow}>
                <TableCell className={classes.tableCell}>
                  {row.disbursement_id}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.total_vendors}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {moment(row.timestamp).format('LL')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {"₦"}{formatMoney(row.total_amount)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </Card>
      <Card>
        
      </Card>
    </div>
  );
}

PaymentHistoryTable.defaultProps = {
  tableHeaderColor: "gray"
};

PaymentHistoryTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


