

import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "components/CustomButtons/Button.js";
import  "assets/css/style.css"

const useStyles = makeStyles(styles);

export default  function Modal ({handleClose, show, children, ...props}){
  const classes = useStyles();

  const showHideClassName =show? "modal display-block": "modal display-none";
//   const {tableHeaderColor } = props;
  return (
    <div className={showHideClassName}>
        <section className="modal-main">
            {children}
            <Button type="button"  onClick={handleClose}> Close </Button>
        </section>
       
    </div>
  );
}

Modal.defaultProps = {
  tableHeaderColor: "gray"
};

Modal.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


