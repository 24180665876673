import totalFirebaseApp from '../../Config/config';
import {SET_USERS,  LOADING_DATA, DELETE_USER, LOGIN_USER, LOGIN_USER_ERROR, SET_ERRORS, CLEAR_ERRORS, SIGN_OUT_USER} from '../Redux/type';


let firebase = totalFirebaseApp.adminAuth;


export const loginAction = (
    email, password , history
) => async dispatch =>{

    let crossAuthListener;
    let keyToken = ""

    firebase.signInWithEmailAndPassword(email, password).then((taskResult) => {
        return taskResult.user.getIdToken();
    }).then((token) => {
        keyToken = token
        localStorage.setItem("RFToken", `${keyToken}`)
        let requestKey = totalFirebaseApp.adminFirestore.collection("crossAuth").doc().id;
        let authData = {
            token: keyToken,
            request_key: requestKey
        }
        crossAuthListener = totalFirebaseApp.adminFirestore.collection("crossAuthTokens").doc(requestKey);
        return totalFirebaseApp.adminFirestore.collection("crossAuth").add(authData);
        // return totalFirebaseApp.adminFirestore.collection("crossAuth").add(authData.token);
    }).then(() => {
        crossAuthListener.onSnapshot((snapshot) => {
            if (snapshot != null && snapshot.exists && snapshot && snapshot.data() != null && snapshot.data().status === "OK") {

                let priceCustomToken = snapshot.data().custom_token_user;
                let productCustomToken = snapshot.data().custom_token_product;
                let vendorCustomToken = snapshot.data().custom_token_vendor;
                let agentCustomToken = snapshot.data().custom_token_agent
                
                let promises = [
                    totalFirebaseApp.priceAuth.signInWithCustomToken( priceCustomToken),
                    totalFirebaseApp.productsAuth.signInWithCustomToken(productCustomToken),
                    totalFirebaseApp.vendorAuth.signInWithCustomToken(vendorCustomToken ),
                    totalFirebaseApp.agentAuth.signInWithCustomToken(agentCustomToken ),
                  
                ]

                return Promise.all(promises).then((results) => {
                    let token = []
                    results.forEach(data => {
                        token.push(data.user.refreshToken)

                    })

                        console.log(token)
                    
                        
                   let tokenState = localStorage.setItem('RFToken', `${token[0]}`)
                    
                   dispatch({
                    type: CLEAR_ERRORS
                })
                    dispatch({
                        type:  LOGIN_USER,
                        payload: tokenState

                    })
                   
                    history.push(`/admin/dashboard`)
                }).catch((err) => {
                    dispatch({
                        type: LOGIN_USER_ERROR,
                        payload: err
                    });
                });
            }
        })
    }).catch((err) => {
        dispatch({
            type: LOGIN_USER_ERROR,
            payload:false
            // authError: err
        });
    });
    
}





export const LogOut = () => dispatch =>{
    localStorage.removeItem('RFToken');
    dispatch({type: SIGN_OUT_USER})
}


export const PasswordReset = (email) => {
    return(dispatch) => {
        firebase.sendPasswordResetEmail(email)
        .then(()=>{
            dispatch({
                type: "PASSWORD_RESET"
            })
        })
    }
}

export const PasswordUpdate = (password)=>{
    return (dispatch)=>{
        firebase.PasswordUpdate(password)
        .then(()=>{
            dispatch({
                type: "PASSWORD_RESET"
            })
        })
    }
}





