
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { Redirect, Link } from 'react-router-dom';
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from '@material-ui/core/TablePagination';
import { fetchOrdersWithCursor, clearCursorListener,getCustomer, listenForProductUpdates } from 'Reducers/Actions/loadingData';
import { useSelector, useDispatch } from "react-redux";
import { GET_ORDER_IN_PROGRESS_DETAILS } from "Reducers/Redux/type";
import { GET_COMPLETED_ORDER_DETAILS } from "Reducers/Redux/type";
import { GET_CANCENCELLED_ORDER_DETAILS } from "Reducers/Redux/type";
import { GET_TOTAL_ORDER_DETAILS } from "Reducers/Redux/type";

const useStyles = makeStyles(styles);

export default function OrdersTable(props) {

  const {tableHeaderColor, orders, marketIds,vendorIds, agentId, handleClick, type,load} = props;
  //console.log("input order:", orders);

  const dispatch = useDispatch();

  const ordersLength = orders.length;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loaded, setLoaded] = React.useState(false);
  // const singleVendorProducts = useSelector((state) => state.dataState[vendorIds[0]]);


  // if (singleVendorProducts) {
  //   cId = singleVendorProducts[Object.keys(singleVendorProducts)[0]].customer_id;
  // }
 

  const focusedOrders = useSelector(state => stateProp(state));


  console.log(vendorIds, agentId)
  console.log(marketIds)
  console.log("focusOrders", focusedOrders)

  React.useEffect(() => {

    if (orders.length > 0) {
     
      dispatch(fetchOrdersWithCursor(orders.slice(rowsPerPage * page, (rowsPerPage * page + rowsPerPage)), reducerType()));
    }
    setLoaded(true);

    return () => clearCursorListener();
  }, [ordersLength, page]);

 


  const handleChangePage = (event, newPage) => {
    //console.log("New page change:", newPage, "Event:", event);
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const header = () => {
    switch (type) {
      case "progress":
        return "Orders In Progress";
      case "completed":
        return "Completed Orders";
      case "cancelled":
        return "Cancelled Orders";
      case "total":
        return "All Orders";
      default:
        return "";
    }
  }

  const headerColor = () => {
    switch (type) {
      case "progress":
        return "warning";
      case "completed":
        return "success";
      case "cancelled":
        return "danger";
      case "total":
        return "primary";
      default:
        return "";
    }
  }

  const colorForTableHeader = () => {
    switch (type) {
      case "progress":
        return classes.warningTableHeader;
      case "completed":
        return classes.successTableHeader;
      case "total":
        return classes.primaryTableHeader;
      default:
        return "";
    }
  }

  const reducerType = () => {
    switch (type) {
      case "progress":
        return GET_ORDER_IN_PROGRESS_DETAILS;
      case "completed":
        return GET_COMPLETED_ORDER_DETAILS;
      case "cancelled":
        return GET_CANCENCELLED_ORDER_DETAILS;
      case "total":
        return GET_TOTAL_ORDER_DETAILS;
      default:
        return "";
    }
  }

  function stateProp(state) {
    switch (type) {
      case "progress":
        return state.dataState.ordersInProgress;
      case "completed":
        return state.dataState.completedOrders;
      case "cancelled":
        return state.dataState.cancelledOrders;
      case "total":
        return state.dataState.allOrders;
      default:
        return [];
    }
  }

  

  if(load){
  return (
    <div className={classes.tableResponsive}>
        <Card>
          <CardHeader color={headerColor()}> <h4>{header()}</h4><p>{moment().format('LL')}</p></CardHeader>
       
          <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={colorForTableHeader()} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Order Id
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Status
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Delivery Option
                  
                  </TableCell>
                     
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                           Amount
                  
                  </TableCell>  
                  
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Creation Time
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        <TableBody>
          {/* orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
          {focusedOrders && focusedOrders.length > 0 ? (focusedOrders.map((order) => (
            <TableRow key={order.order_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(order.order_id, order.customer_id, order.total_price, order.delivery_option, order.order_placed_timestamp )}>
                 
              <TableCell align="left">{order.order_id}</TableCell>
              <TableCell align="left">{order.status}</TableCell>
              <TableCell align="left">{order.delivery_option == 0 ? 'Not included' : 'Included'}</TableCell>
              <TableCell align="left">{order.total_price}</TableCell>
              <TableCell align="left"> { moment( order.order_placed_timestamp).calendar()} </TableCell>
          
            </TableRow>
          ))) : (<div>No active orders.</div>)}
           <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </TableBody>
      </Table>
      </Card>
    </div>
  );
        } else{
          return(
            <div>
              Loading....
            </div>
          )
        }
}

OrdersTable.defaultProps = {
  tableHeaderColor: "gray"
};

OrdersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


