

import React, {useEffect, useState} from "react";
import { useSelector, useDispatch  } from 'react-redux';
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { Link } from 'react-router-dom';
import Button from "components/CustomButtons/Button.js";
import {getAgents } from "Reducers/Actions/loadingData";
import Modal from "views/pages/modal"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import NewAgent from "./registerAgent";
import { unsubscribeAgentListener } from '../../../Reducers/Actions/loadingData'

const useStyles = makeStyles(styles);

// function createData(id, name, email, phone, level) {
//   return [ id, name, email, phone, level ];
// }
 

// const rows = [
//     createData('078', "Marcus Vee", "marc@gmail.com", "0705678", 4),
//     createData('064', "Seyi Law", "seyi@gmail.com", "09045678", 3),
//     createData('045', "Madu Ben", "madu@gmail.com", "08067465", 6),
//     createData('034', "Femi Bright", "bright@gmail", "056434", 3),
//     createData('045', "Mende Sane", "mende@gmail.com", "0456778", 9),
//   ];

export default function AgentsTable(props) {
  const classes = useStyles();
  const agents = useSelector(state => state.dataState.agents)
  const readLoad = useSelector(state => state.dataState.loading)
  const [load, setLoad] = useState(false)
  const [dataLoad, setDataLoad] = useState(false)
  // const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const update = useSelector((state) => state.dataState.update);


const dispatch = useDispatch();

const loadData = ()=>{
  setLoad(true)
  dispatch(getAgents())
  setDataLoad(readLoad)

}

// const showModal = () => {
//   setShow(true)
// }

// const hideModal = () =>{
//   setShow(false)
// }

const handleClose = (value) => {
  setOpen(false)
  // setSelectedValue(value)

 }; 

 const handleClickOpen = () => {
  setOpen(true);
};

const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {

    loadData()
    return () => unsubscribeAgentListener();
  }, [update])

  const handleClick = (id) =>{
    console.log("you click me")
    return props.history.push(`/app/agents/${id}`)
  
}
  const {tableHeaderColor } = props;

 
  console.log("AGENTS:", agents);
  return (
    <div className={classes.tableResponsive}>
      <Card>
            
        <CardHeader color="warning">
          <GridContainer>
            <GridItem xs={12} sm={6} md={10}>
            <h4>Agents List</h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={2}>
              <Button color="white" onClick={handleClickOpen } style={{color: "#FE5E00"}}>Add Agent</Button>
              {/* <Select options={agents?agents.map((t) => t.user_name) : []} onChange={(v)=>console.log(v)}/> */}
          </GridItem>
          </GridContainer>
        </CardHeader>
        <NewAgent open={open} onClose={handleClose}  />   
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.warningTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            ACCOUNT ID
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                             Email
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Phone
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Market
                  
                  </TableCell>  
             
            </TableRow>
           
          </TableHead>
        
        <TableBody>
         
          {agents && agents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((agent) => {
            return (
              <TableRow key={agent.id} className={classes.tableBodyRow}
               onClick={(e)=> handleClick(agent.user_id)}
              >
                  <TableCell component="th" scope="row">
                    {agent.user_id}
                  </TableCell>
                  <TableCell align="left">{agent.user_name}</TableCell>
                  <TableCell align="left">{agent.user_email}</TableCell>
                  <TableCell align="left">{agent.user_phone_number}</TableCell>
                  <TableCell align="left">{agent.market}</TableCell>
              </TableRow>
            )
          })}
          {(!agents || (agents && agents.length === 0)) && (
            <div style={{display: "flex", justifyContent: "center", backgroundColor: "", width: "100%"}}>
              <p>{" "}</p>
              <p>No data available.</p>
            </div>
          )}
         <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={agents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableBody>
      </Table>
      {/* <Modal show={show} handleClose={hideModal} >
          <NewAgent handleClose={hideModal} />
        </Modal> */}
      </Card>
     
    </div>
  );
      
}

AgentsTable.defaultProps = {
  tableHeaderColor: "gray"
};

AgentsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};








