
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import EditDispatcher from "./update"
import Modal from "views/pages/modal";

import avatar from "assets/img/faces/kendall.jpg";
import {getSingleDispatcher, deleteRider, updateDispatcherStatus } from "Reducers/Actions/loadingData"

// import Profile from './UserProfile';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


const useStyles = makeStyles(styles);
export default function Disparcher(props){
  
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [active, setActive]= useState(null)
   
    const { ...rest }= props;

    const [load, setLoad] = useState("");
    const data = useSelector(state=> state.dataState.dispatcher)
    const dispatch = useDispatch();
    let id = props.match.params.id;

    let dispatcher = data;
    useEffect(() => {
    
      dispatch(getSingleDispatcher(id))
    }, [id])


      const handleSubmit = ()=>{
        console.log("submit")
      }
      const handleActivation= ()=>{
        dispatch(updateDispatcherStatus(id, dispatcher.status))
        setActive(dispatcher.status === 'active'?"inactive":"active") 
      }


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false)
   }; 

      const deleteHandle = () => {
        let info = prompt(
          "You are bout to delete document, type yes if you wish to proceed"
        );
        if (info) {
          dispatch(deleteRider(id));
          props.history.push("/admin/dispatchers");
        }
      };
      

    
      if(dispatcher){
    return (
        <div>
        <Header
        absolute
        color="black"
        brand="Price n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
          <GridContainer justify={"center"}
      style={{
        marginTop:"100px"
      }}>
        
        <GridItem xs={12} sm={12} md={8}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={dispatcher.user_avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
             <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
              <span className={classes.cardTitle} style={{color:"#ff4c00"}}>{dispatcher.user_name}</span>
              <p> Price n' Pay Dispatcher</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={10}>
              
          
      
        </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>ID </h5>
    <p>{dispatcher.user_id}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Email </h5>
    <p>{dispatcher.user_email}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> Phone No </h5>
    <p>{dispatcher.user_phone_number}</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Total Deliveries</h5>
    <p>{dispatcher.total_deliveries}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Market</h5>
    <p>{dispatcher.market}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> Star Rating</h5>
    <p >{dispatcher.rating}</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
    <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>LICENSE NO</h5>
              <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{dispatcher.vehicle_license}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> ACTIVE</h5>
              <p style={{color: "#ff4c00", fontWeight: "500px"}}>{active === null? dispatcher.status: active} </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> ACTIVE DELIVERIES  </h5>
    <p>{dispatcher.total_deliveries}</p>
              </GridItem>
              </GridContainer>          
              <GridContainer>
                <GridItem xs={12} sm={4} md={4} style={{margin: "auto"}}>
                <Button color={dispatcher.status === 'active'?"danger":"success"} onClick={(e)=>handleActivation(e)}>{dispatcher.status === 'active' ? 'DEACTIVE RIDER' : 'ACTIVATE RIDER'}</Button>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
             
                <Button color="info" onClick={handleClickOpen}>Edit Details</Button>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                <Button color="info" onClick={(e)=> handleSubmit()}>VIEW DELIVERIES</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <EditDispatcher onClose={handleClose} id={id} dispatcher={data} open={open}/>
        </div>
    )
      }else{
        return <div> Loading....</div>
      }
                  
}




