

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import NewMarket from "./newMarket";


import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Modal from "views/pages/modal"
// import CreateDispatcher from "./addDispatcher";
import Button from "components/CustomButtons/Button.js";
import {getMarkets, removeMarketsListener} from "Reducers/Actions/loadingData"
import TablePagination from "@material-ui/core/TablePagination";
import {useSelector, useDispatch} from "react-redux"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";



const useStyles = makeStyles(styles);


export default function MarketTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const markets = useSelector(state => state.dataState.markets)
  const update = useSelector(state => state.dataState.update);

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  
  const handleClose = () =>{
    setOpen(false)
  }

 
  const handleClick = (id) =>{
   
    return props.history.push(`/app/markets/${id}`)
  
}



const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

useEffect(() => {
  dispatch(getMarkets())
  return () => removeMarketsListener();
}, [update])

  const {tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
        <Card>
        <CardHeader color="warning">
          <GridContainer>
            <GridItem xs={12} sm={6} md={9}>
            <h4> Market Lists</h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height:"100%"}}>
                <Button color="white" onClick={handleClickOpen } style={{color: "#FE5E00"}}>Add Market</Button>
              </div>
        {/* <Button color="warning"  onClick={handleClickOpen }>Add Market</Button> */}
          </GridItem>
          </GridContainer>
        </CardHeader>
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.warningTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Market Id
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                          Location
                  
                  </TableCell>
                 
                  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {markets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow key={row.market_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(row.market_id)}>
            
                    <TableCell className={classes.tableCell} >
                      {row.market_id}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.location}
                    </TableCell>
                    
            
              </TableRow>
            );
          })}

              <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={markets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableBody>
      </Table>
      </Card>
      {/* <Modal show={show} handleClose={hideModal}>
          <CreateDispatcher handleClose={hideModal} />
        </Modal>
        <div>
      <Button color="warning"  onClick={showModal}> Add Dispatcher </Button>
      </div> */}
      <NewMarket open={open} onClose ={handleClose} />
    </div>
  );
}

MarketTable.defaultProps = {
  tableHeaderColor: "gray"
};

MarketTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


