import React, {useEffect, useState, Component} from "react";
import { useSelector, useDispatch,  } from 'react-redux';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import EditAgent from "./updateAgent"
import Modal from "views/pages/modal";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { white } from "material-ui/styles/colors";
import {getSingleAgent, agentWithOrders, deleteAgent, updateAgentStatus } from "Reducers/Actions/loadingData";

import avatar from "assets/img/faces/marc.jpg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


const useStyles = makeStyles(styles);

export default function Agent(props) {
  // const id = props.match.params.id;
  const classes = useStyles();
  const dispatch = useDispatch()
  const data = useSelector(state => state.dataState.agent);
  const agentOrders = useSelector(state => state.dataState.agentOrders)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [active, setActive] = useState(null)

  let agent = data;

  // const showModal = () => {
  //   setShow(true)
  // }


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false)
  };


  const handleFormOpen = () => {
    setOpenForm(true);
  };
  const handleFormClose = (value) => {
    setOpenForm(false)
  };

  //  const loadData=()=>{
  //    let id= props.match.params.id;
  //    dispatch(getSingleAgent(id))

  //  }


  useEffect(() => {
    let id = props.match.params.id;
    if (id) {
      dispatch(getSingleAgent(id));
      dispatch(agentWithOrders(id))
    }
    // loadData()

  }, [id])

  const deleteHandle = () => {
    let info = prompt(
      "You are about to delete document, type yes if you wish to proceed"
    );
    if (info) {
      dispatch(deleteAgent(id));
      props.history.push("/admin/agents");
    }
  };


  let id = props.match.params.id
  console.log(agent)

  const handleSubmit = (e) => {
    console.log("Cliked")
  }

  const handleActivation = () => {
    dispatch(updateAgentStatus(id))
    setActive("inactive")
  }

  const { ...rest } = props
  if (agent) {
    return (
      <div>
        <Header
          absolute
          color="black"
          brand="Price n' Pay"
          // rightLinks={<HeaderLinks />}
          {...rest}
        />
        <GridContainer justify={"center"}
          style={{
            marginTop: "100px"
          }}>

          <GridItem xs={12} sm={12} md={8}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={agent.user_avatar} alt={agent.user_name} />
                </a>
              </CardAvatar>
              <CardBody profile>
                <GridContainer >
                  <GridItem xs={12} sm={12} md={4}>
                    <h4 className={classes.cardTitle} style={{ color: "#ff4c00" }}>{agent.user_name}</h4>
                    <p> Price n' Pay Agent</p>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>ACCOUNT ID </h5>
                    <h6 className={classes.cardTitle}>{agent.user_id}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>Email </h5>
                    <h6 className={classes.cardTitle}>{agent.user_email}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}> Phone No </h5>
                    <h6 className={classes.cardTitle}>{agent.user_phone_number}</h6>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>Total Orders Handled</h5>
                    <h6 className={classes.cardTitle}>{agent.total_orders}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>Total Orders Re-Assigned </h5>
                    <h6 className={classes.cardTitle}>{agent.reassigned_orders}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}> Market </h5>
                    <h6 className={classes.cardTitle}>{agent.market}</h6>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>STATUS</h5>
                    <h6 className={classes.cardTitle} style={{ color: "#ff4c00", fontWeight: "500" }}>{active === null ? agent.status : active}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}>MARKET PRESENCE </h5>
                    <h6 className={classes.cardTitle} style={{ color: "#ff4c00", fontWeight: "500px" }}>{agent.market_status}</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h5 className={classes.cardTitle} style={{ color: "#000000", fontWeight: "500" }}> CREATION DATE </h5>
                    <h6 className={classes.cardTitle} >{agent.creation_date}</h6>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={3} md={4} style={{ margin: "auto" }}>
                    <Button color="danger" onClick={(e) => handleActivation(e)}>Deactivate Agent</Button>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={4}>

                    <Button color="info" onClick={handleFormOpen}>Edit Agent Details</Button>

                  </GridItem>
                  <GridItem xs={12} sm={3} md={4}>
                    <Button color="info" onClick={handleClickOpen}>Active Orders</Button>
                  </GridItem>
                  {/* <GridItem xs={12} sm={3} md={3}>
                <Button color="info" onClick={(e)=> handleSubmit(e)}>Re-Assigned Orders</Button>
                </GridItem> */}
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <EditAgent onClose={handleFormClose} open={openForm} id={id} agent={data} />
        <SimpleDialog onClose={handleClose} open={open} agentOrders={agentOrders} />
      </div>
    );
  } else {
    return (
      <div>
        Loading....
      </div>
    )
  }

}


function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, agentOrders } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);

  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">View Orders</DialogTitle>
      {agentOrders != null ? <List>
        {agentOrders.map((item) => (
          <ListItem button onClick={() => handleListItemClick(item)} key={item.id}>
            {item.status === "active" ?
              <React.Fragment>
                <ListItemText primary={item.order_id} />
                <ListItemText primary={item.status} />
              </React.Fragment> : null

            }

          </ListItem>
        ))}
      </List> : <div>Loading...</div>}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


