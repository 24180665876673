
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { white } from "material-ui/styles/colors";

import avatar from "assets/img/faces/kendall.jpg";
import {getSingleVendor,  getVendorWithOrder, getVendorWithProduct, deleteVendor, updateVendorStatus} from "Reducers/Actions/loadingData"

// import Profile from './UserProfile';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


const useStyles = makeStyles(styles);
export default function Vendor(props){
  
    const classes = useStyles()
    const { ...rest }= props;

    const [load, setLoad] = useState(false);
    const vendor= useSelector(state=> state.dataState.vendor)
    const vendOrders= useSelector(state=> state.dataState.vendOrders)
    const vendProducts= useSelector(state=> state.dataState.vendProducts)
    
    const [selectedValue, setSelectedValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [marketId, setMarketId] = useState("")
    const [active, setActive]=useState("")

    const dispatch = useDispatch();
    let id = props.match.params.id;

    console.log(vendProducts)

    useEffect(() => {
      dispatch(getSingleVendor(id))
      dispatch( getVendorWithOrder(id))
      dispatch(getVendorWithProduct(id))
     
      setLoad(true)
    }, [id])


    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickOpenProduct = () => {
      setOpenProduct(true);
    };

    const deleteHandle = () => {
      let info = prompt(
        "You are bout to delete document, type yes if you wish to proceed"
      );
      if (info) {
        dispatch(deleteVendor(id));
        props.history.push("/admin/vendors");
      }
    };

    const handleClose = (value) => {
     setOpen(false)
     setSelectedValue(value)
 
    }; 

    const handleCloseProduct = (value) => {
      setOpenProduct(false)
      setSelectedValue(value)
     };

     const handleListItemClick = (value) => {
      handleClose(value);
      props.history.push(`/app/orders/${value}/${id}/${vendor.market_id}`)
    };


    const handleActivation = ()=>{
      dispatch(updateVendorStatus(id))
      setActive("inactive")
    }
 
      if(load){
    return (
        <div>
        <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
          <GridContainer justify={"center"}
      style={{
        marginTop:"100px"
      }}>
        
        <GridItem xs={12} sm={12} md={8}>
          <Card profile>
            {/* <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar> */}
            <CardBody profile>
             <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
              <span className={classes.cardTitle} style={{color:"#ff4c00"}}>{vendor.name}</span>
              <p> Price n' Pay Vendor</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Account Id </h5>
    <p>{vendor.user_id}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Email </h5>
    <p>{vendor.email}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> Phone No </h5>
    <p>{vendor.phone_number}</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
               {/* <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Total orders</h5>
    <p>{vendor.total_delivery}</p>
              </GridItem> */}
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Market</h5>
    <p>{vendor.market_name}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> Status</h5>
    <p >{active === ""?vendor.account_status:active}</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={4} md={4} style={{margin: "auto"}}>
               <Button color="danger" onClick={(e)=>handleActivation(e)}>DEACTIVATE VENDOR</Button>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
             <Button color="info" onClick={handleClickOpen }>VIEW CURRENT ORDERS </Button>
               </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                <Button color="info" onClick={ handleClickOpenProduct }>VIEW PRODUCTS</Button>               
                </GridItem>
             </GridContainer>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    
        <SimpleDialog  open={open} onClose={handleClose}  vendOrders={ vendOrders}  handleListItemClick= {handleListItemClick}/>

        <GridContainer>
       <GridItem xs={12} sm={12} md={8}>
       <ProductDialog  open={openProduct} onClose={handleCloseProduct}  vendProducts={ vendProducts}/>
       </GridItem>
       </GridContainer>
              
        </div>
    )
      } else{
        return <div>Loading.....</div>
      }
                  
}

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open,  vendOrders, handleListItemClick } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  //   props.history.push(`/app/orders/${value}`)
  // };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">View Orders</DialogTitle>
      {vendOrders != null ? <List>
                  {vendOrders.map((item) => (
                      <ListItem button onClick={() => handleListItemClick(item.id)} key={item.id}>
                          <ListItemText primary={item.id}/>
                       </ListItem>
                  ))}
               </List> : <div>Loading...</div>}
    </Dialog>
  );
}







  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };


  
function ProductDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open,  vendProducts } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">View Products</DialogTitle>
      {vendProducts != null ? <List>
                  {vendProducts.map((item) => (
                    
                      <ListItem button onClick={() => handleListItemClick(item)} key={item.id}>
                         <p> <ListItemText primary={item.brand}/></p>
                         <p> <ListItemText primary={item.name}/></p>
                         <p> <ListItemText primary={item.extra_info}/></p>
                         <p> <ListItemText primary={item.status}/></p><br/>
                  
                          { 
                            item.unit_prices !=null? Object.keys(item.unit_prices).map((res, index) =>(
                              <ListItem  key={index}>
                              <ListItemText primary={item.unit_prices[res]}/>
                              </ListItem>
                            )): null
                          
                        }
                       </ListItem>
                  ))}
               </List> : <div>Loading...</div>}
    </Dialog>
  );
}







  ProductDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };