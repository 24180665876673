import React, {useState, useEffect}from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";

import NewProduct from "./postProduct";
import GridItem from "components/Grid/GridItem.js";

import {useSelector, useDispatch} from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import { getPendingVerifications, unsubscribePendingVerificationsListener } from "Reducers/Actions/loadingData";
import moment from "moment";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
const useStyles = makeStyles(styles);

export default function ProductTable(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const pendingVerifications = useSelector(state => state.dataState.pendingVerifications)
  const update = useSelector(state => state.dataState.update)
  const showModal = () => {
    setShow(true)
  }

  useEffect(() => {
    dispatch(getPendingVerifications());
    return () => unsubscribePendingVerificationsListener();
  }, [update])
  
  const handleClose = (value) => {
    setOpen(false)
    // setSelectedValue(value)
  
   }; 
  
   const handleClickOpen = () => {
    setOpen(true);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) =>{
    return props.history.push(`/app/verify_products/${id}`) 
}
  return (
    <div>
      {pendingVerifications ? (
        <div className={classes.tableResponsive}>
          <Card>

          <CardHeader color="primary">
            <h4>Pending Verifications</h4>
          </CardHeader>
          
          
              <Table className={classes.table}>
        
        {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
    
          <TableHead className={classes.primaryTableHeader} > 
          
          <TableRow className={classes.tableHeadRow}>  
          <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}>
                          Product Id
                
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}>
                          Name
                
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}>
                        New Brand
                
                </TableCell>
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}>
                          Creation Date
                </TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
        {pendingVerifications!=null && pendingVerifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
          return (
            <TableRow key={product.item_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(product.item_id)}>
                  <TableCell className={classes.tableCell} >
                    {product.product_id}
                  </TableCell>
                  <TableCell className={classes.tableCell} >
                    {product.product_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} >
                    {product.new_brand}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(product.timestamp).format('LL')}
                  </TableCell>
          
            </TableRow>
          );
        })}

            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={pendingVerifications.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </TableBody>
    </Table>
        </Card>

        <NewProduct onClose={handleClose} open={open}/>
      </div>
      ) : (<div>Loading...</div>)}
    </div>
  );
}

ProductTable.defaultProps = {
  tableHeaderColor: "gray"
};

ProductTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
