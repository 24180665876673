
import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import User from "views/pages/Users/user";
import UserUpdate from "views/pages/Users/upDateUser";
import Agent from "views/pages/Agents/agent";
import AgentUpdate from "views/pages/Agents/agent";
import RegisterAgent from "views/pages/Agents/registerAgent";
import Market from "views/pages/Market/market"
import Product from "views/Products/product";
import Verify from "views/Products/verify";
import Dispatcher from "views/pages/Dispatchers/dispatcher";
import Vendor from "views/pages/Vendors/vendor";
import Order from "views/pages/Orders/OrdersTypes/loadScreen";
import Payment from "views/pages/Payments/payment.js";


const appUserRoutes = () =>(
    <React.Fragment>
     <Route path="/app/users/:id" component={User}/>
     <Route path="/app/user/update/:id" component={UserUpdate}/>
     <Route path="/app/agents/:id" component={Agent}/>
     <Route path="/app/agent/update/:id" component={AgentUpdate}/>
     <Route path="/app/dispatchers/:id" component={Dispatcher}/>
      <Route path="/app/vendors/:id" component={Vendor}/>
      <Route path="/app/payments/:id" component={Payment}/>
      <Route path="/app/registerAgent" component={RegisterAgent}/>
      <Route path="/app/markets/:id" component={Market}/> 
      <Route path="/app/products/:id" component={Product}/> 
      <Route path="/app/verify_products/:id" component={Verify}/> 
  <Route path="/app/orders/:order_id/:customer_id/:total_amount/:delivery_option/:order_timestamp" component={Order}/> 
     
    </React.Fragment>
)

export default appUserRoutes;