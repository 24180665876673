import React from 'react';
import { Route, Redirect} from 'react-router-dom'
import {connect } from 'react-redux';

const authRoute= ({component: Component, authenticated, ...rest})=>(
    <Route 
    {...rest}
    render={(props)=> !authenticated? <Redirect to='/login'/> : <Component {...props}/> }
    />

)
  
const mapStateToProps = (state) =>({
    authenticated: state.authState.authenticated
})
export default connect(mapStateToProps)(authRoute);;