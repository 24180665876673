import React, { Component, useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import Profile from './UserProfile';
import { postMarket, getAgents } from "Reducers/Actions/loadingData";
import Select from "react-select";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles(styles);
export default function AddMarket(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(false);
  const agents = useSelector(state => state.dataState.agents)

  const {open, onClose} = props

  const [state, setState] = useState({
    name: "",
    description: "",
    location: "",
    latitude: "",
    longitude: "",
    agent: null
  });

  useEffect(() => {
    dispatch(getAgents())
  }, []);

  const getAgentNames = () => {
    let res = [];
    agents &&
      agents.map((agent) => {
        res.push({value: agent.user_name, label: agent.user_name, ...agent});
      });

    return res;
  };

  const handleAgentSelection = (event)=>{  
    state.agent = event;
  }

  const handleSubmit = ()=>{

   const data = {
      name: state.name,
      description: state.description,
      location: state.location,
      latitude: state.latitude,
      longitude: state.longitude,
      agent: state.agent
    }
    dispatch(postMarket(data));
    //setMessage(true)
    onClose();
  }

  const invalid = state.name === '' || 
    state.location === '' || state.latitude.length === 0 || 
    isNaN(state.latitude) || state.longitude.length === 0 || 
    isNaN(state.longitude) || state.agent === null;

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title"></DialogTitle>
        <br/>
        <CardHeader color="warning" className={classes.cardHeader}>
          <h4 className={classes.cardTitleWhite}>
            ADD NEW MARKET
                </h4>
          <p className={classes.cardCategoryWhite}>
            Please complete all fields
                </p>
        </CardHeader>
        <CardBody>
          {/* <form onSubmit={this.handleSubmit}> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Market Name"
                id="name"
                name="name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: false,
                  type: "text",
                  value: state.name,
                  onChange: e => setState({ ...state, name: e.target.value }),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Location"
                id="location"
                name="location"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: state.location,
                  onChange: e => setState({ ...state, location: e.target.value }),
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Latitude"
                id="latitude"
                name="latitude"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  value: state.latitude,
                  onChange: e => setState({ ...state, latitude: e.target.value }),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Longitude"
                id="longitude"
                name="longitude"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  value: state.longitude,
                  onChange: e => setState({ ...state, longitude: e.target.value }),
                }}
              />
            </GridItem>
          </GridContainer>
          <br/>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Description (Optional)"
                id="description"
                name="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: false,
                  type: "text",
                  value: state.description,
                  onChange: e => setState({ ...state, description: e.target.value }),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <span>Assign agent to market</span>
              <Select
                options={getAgentNames()}
                onChange={(value) => handleAgentSelection(value)}
                value={state.agent}
                //styles={customStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: '#FE5E00',
                    primary: '#00BFFE',
                  },
                })}
                menuPosition="fixed"
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter justify={"center"}>
          <Button color="warning" disabled={invalid} onClick={(e) => handleSubmit(e)}>
            {!message ? "Add Market" : "Added"} </Button>
        </CardFooter>

      </Dialog>
    </div>
  )

}