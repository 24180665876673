import totalFirebaseApp from '../../Config/config';
import { USER_ACCESS, SET_ERRORS, CLEAR_ERRORS, REGISTER_USER, REGISTER_USER_ERROR } from '../Redux/type';

let firebase = totalFirebaseApp.adminAuth;

export const registerAction = (credentials, history) => async (dispatch) => {

  let crossAuthListener;
  let userId;

  totalFirebaseApp.adminFirestore.collection("user_access_tokens").doc(credentials.accessToken).get()
    .then(snapshot => {
      if (snapshot.exists) {
        firebase.createUserWithEmailAndPassword(credentials.email, credentials.password).then((doc) => {
          userId = doc.user.uid;

          return doc.user.getIdToken();
        }).then((token) => {
          localStorage.setItem("RFToken", `${token}`)
          let requestKey = totalFirebaseApp.adminFirestore.collection("crossAuth").doc().id;
          let authData = {
            request_key: requestKey,
            token: token
          }
          crossAuthListener = totalFirebaseApp.adminFirestore.collection("crossAuthTokens").doc(requestKey);
          console.log(crossAuthListener)
          return totalFirebaseApp.adminFirestore.collection("crossAuth").add(authData);
        }).then(() => {
          crossAuthListener.onSnapshot((snapshot) => {
            if (snapshot != null && snapshot.exists && snapshot && snapshot.data() != null && snapshot.data().status === "OK") {
              let priceCustomToken = snapshot.data().custom_token_user;
              let productCustomToken = snapshot.data().custom_token_product;
              let vendorCustomToken = snapshot.data().custom_token_vendor;
              let agentCustomToken = snapshot.data().custom_token_agent


              let promises = [
                totalFirebaseApp.priceAuth.signInWithCustomToken(priceCustomToken),
                totalFirebaseApp.productsAuth.signInWithCustomToken(productCustomToken),
                totalFirebaseApp.vendorAuth.signInWithCustomToken(vendorCustomToken),
                totalFirebaseApp.agentAuth.signInWithCustomToken(agentCustomToken),

              ]

              return Promise.all(promises).then((results) => {
                // 'results' contains auth parameters for bossbus, bossDriver, and bossOperations.
                return totalFirebaseApp.adminFirestore.collection("users").doc(userId).set({
                  user_name: credentials.name,
                  user_email: credentials.email,
                  user_phone_number: credentials.phone,
                  user_access_token: credentials.accessToken
                });

              })
            }
          })
        }).then(() => {
          return totalFirebaseApp.adminFirestore.collection("user_access_tokens").doc(credentials.accessToken).delete()
        }).then(() => {
          dispatch({
            type: REGISTER_USER,
            payload: true
          })
          history.push(`/admin/dashboard`)
        }).catch((err) => {
          //alert(err)
          dispatch({
            type: REGISTER_USER_ERROR,
            payload: err.message,
          })
        });
      } else {
        dispatch({
          type: REGISTER_USER_ERROR,
          payload: "Invalid access token. Please collect access token from your admin.",
        })
      }
    }).catch((err) => {
      //alert(err)
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: err.message,
      })
    });
}


export const postAccess= (token) => dispatch =>{
  totalFirebaseApp.adminFirestore.collection("user_access_tokens").doc(token).set({
    status: "unused",
    creation_date: Date.now()
  }).then(()=>{
    dispatch({
      type: USER_ACCESS,
      payload: token
    })
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err.message
    })
  })
}











