

export const GET_SINGLE_AGENT = "GET_SINGLE_AGENT";
export const LOADING_DATA= " LOADING_DATA";
export const DELETE_AGENT ="DELETE_AGEN"; 
export const POST_AGENT = "POST_AGENT";
export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_ERROR = "GET_AGENTS_ERROR"
export const GET_MARKETS = "GET_MARKETS";
export const CLEAR_ERRORS =" CLEAR_ERRORS"
export const LOADING_UI = "LOADING_UI";
export const SET_ERRORS ="SET_ERRORS"
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const POST_AGENT_ERROR = "POST_AGENT_ERROR"
export const SET_IMAGE_URL = "SET_IMAGE_URL";
export const SET_IMAGE_ERROR = "SET_IMAGE_ERROR";
export const SET_USERS = "SET_USERS";
export const SET_USER ="SET_USER";
export const LOGIN_USER = "LOGIN_USER";
export const SET_USERS_ERROR = "SET_USERS_ERROR";
export const DELETE_USER = "DELETE_USER";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const  GET_USERS = " GET_USERS";
export const  GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_AGENT = "GET_AGENT";
export const GET_SINGLE_USER = "GET_SINGLE_USER";
export const USER_UPDATE = " USER_UPDATE";
export const CREATE_DISPATCHER = "CREATE_DISPATCHER";
export const CREATE_DISPATCHER_ERROR = "CREATE_DISPATCHER_ERROR";
export const GET_ALL_ORDERS = " GET_ALL_ORDERS";
export const GET_ORDER_IN_PROGRESS_DETAILS = "GET_ORDER_IN_PROGRESS_DETAILS";
export const GET_CANCENCELLED_ORDER_DETAILS = "GET_CANCENCELLED_ORDER_DETAILS"
export const GET_COMPLETED_ORDER_DETAILS = "GET_COMPLETED_ORDER_DETAILS";
export const GET_TOTAL_ORDER_DETAILS = "GET_TOTAL_ORDER_DETAILS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const  LISTEN_FOR_PRODUCT_UPDATES = "LISTEN_FOR_PRODUCT_UPDATES";
export const GET_DISPATCHERS = "GET_DISPATCHERS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const  GET_SINGLE_DISPATCHER = "GET_SINGLE_DISPATCHER"
export const  GET_SINGLE_MARKET="GET_SINGLE_MARKET";
export const GET_VENDORS = "GET_VENDORS";
export const GET_SINGLE_VENDOR ="GET_SINGLE_VENDOR";
export const  GET_VENDOR_WITH_ORDER= "GET_VENDOR_WITH_ORDER";
export const GET_VENDOR_WITH_PRODUCT = "GET_VENDOR_WITH_PRODUCT";
export const UPDATE_AGENT = "UPDATE_AGENT";
export const AGENT_WITH_ORDERS ="AGENT_WITH_ORDERS";
export const DELETE_DISPATCHER = "DELETE_DISPATCHER";
export const DELETE_VENDOR = "DELETE_VENDOR"
export const UPDATE_DISPATCHER = "UPDATE_DISPATCHER";
export const USER_ACCESS = "USER_ACCESS";
export const MARKET_AGENT_UPDATE = "MARKET_AGENT_UPDATE";
export const  ADD_NEW_MARKET = "ADD_NEW_MARKET";
export const ADD_NEW_PRODUCT ="ADD_NEW_PRODUCT";
export const GET_SINGLE_PRODUCT= "GET_SINGLE_PRODUCT";
export const GET_PRICEPAY_PRODUCTS = "GET_PRICEPAY_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const STATUS_CHANGE= "STATUS_CHANGE";
export const GET_PENDING_VERIFICATIONS = "GET_PENDING_VERIFICATIONS";
export const GET_PENDING_PRODUCT = "GET_PENDING_PRODUCT";
export const GET_ORDER_MARKETS = "GET_ORDER_MARKETS";
export const BULK_PAYMENT = "BULK_PAYMENT";
export const GET_PENDING_DISBURSEMENT = "GET_PENDING_DISBURSEMENT";
export const GET_DISBURSEMENT_HISTORY = "GET_DISBURSEMENT_HISTORY";