import {
  GET_AGENTS,
  LOADING_DATA,
  DELETE_AGENT,
  POST_AGENT,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ERRORS,
  GET_SINGLE_AGENT,
  GET_AGENTS_ERROR,
  GET_MARKETS,
  GET_USERS,
  GET_SINGLE_USER,
  USER_UPDATE,
  CREATE_DISPATCHER,
  GET_ALL_ORDERS,
  GET_SINGLE_ORDER,
  GET_CUSTOMER,
  LISTEN_FOR_PRODUCT_UPDATES,
  GET_DISPATCHERS,
  GET_ALL_PRODUCTS,
  GET_SINGLE_DISPATCHER,
  GET_SINGLE_MARKET,
  GET_VENDORS,
  GET_SINGLE_VENDOR,
  GET_VENDOR_WITH_ORDER,
  GET_VENDOR_WITH_PRODUCT,
  UPDATE_AGENT,
  AGENT_WITH_ORDERS,
  DELETE_DISPATCHER,
  DELETE_VENDOR,
  UPDATE_DISPATCHER,
  MARKET_AGENT_UPDATE,
  ADD_NEW_MARKET,
  ADD_NEW_PRODUCT,
  GET_SINGLE_PRODUCT,
  GET_PRICEPAY_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  STATUS_CHANGE,
  CREATE_DISPATCHER_ERROR,
  GET_PENDING_VERIFICATIONS,
  GET_PENDING_PRODUCT,
  GET_ORDER_MARKETS, BULK_PAYMENT,
  GET_PENDING_DISBURSEMENT,
  GET_DISBURSEMENT_HISTORY
} from "../Redux/type";

import moment from "moment";

import totalFirebaseApp from "../../Config/config";


const db = totalFirebaseApp.adminFirestore;
const dbCustomer = totalFirebaseApp.customerFirestore;
const dbAgent = totalFirebaseApp.agentFirestore;

const dbPrice = totalFirebaseApp.priceFirestore;
const dbVendor = totalFirebaseApp.vendorFirestore;

const dbProduct = totalFirebaseApp.productsFirestore;

const productsStorage = totalFirebaseApp.productsStorage;

const geoPoint = totalFirebaseApp.geoPoint;

let singletonCursorQuery, singletonOrderQuery, singletonDispatcherQuery, unsubscribeAgent, 
  unsubscribeMarkets, unsubscribePendingVerifications, unsubscribePendingDisbursement;
let singletonProductsQuery = [];

//get all screams
export const getMarkets = () => (dispatch) => {
  dispatch({ type: LOADING_DATA, payload: {showloading: false, status: null} });

  let documents = [];
  unsubscribeMarkets = dbProduct.collection("markets").onSnapshot((snapshot) => {
    snapshot.docs.map((doc) => documents.push(doc.data()));
    dispatch({
      type: GET_MARKETS,
      payload: documents,
    });
  });
};

export const removeMarketsListener = () => {
  if (unsubscribeMarkets) unsubscribeMarkets();
}

export const getAgents = () => (dispatch) => {
  dispatch({ type: LOADING_DATA, payload: {showloading: false, status: null} });

  let documents = [];
  unsubscribeAgent = dbAgent.collection("users").onSnapshot((snapshot) => {
    snapshot.docs.map((doc) => documents.push(doc.data()));
    dispatch({
      type: GET_AGENTS,
      payload: documents,
    });
  })
  // dbAgent
  //   .collection("users")
  //   .onSnapshot()
  //   .then((snapshot) => {
  //     snapshot.docs.map((doc) => {
  //       let document = { id: doc.id, ...doc.data() };
  //       return documents.push(document);
  //     });
  //   })
  //   .then(() => {
  //     dispatch({
  //       type: GET_AGENTS,
  //       payload: documents,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: GET_AGENTS_ERROR,
  //       payload: [],
  //     });
  //   });
};

export const unsubscribeAgentListener = () => {
  if (unsubscribeAgent) unsubscribeAgent();
}

export const getVendors = () => (dispatch) => {
  dispatch({ type: LOADING_DATA, payload: {showloading: false, status: null} });

  let documents = [];
  dbVendor
    .collection("users")
    .get()
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        let document = { id: doc.id, ...doc.data() };
        return documents.push(document);
      });
    })
    .then(() => {
      dispatch({
        type: GET_VENDORS,
        payload: documents,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const getSingleVendor = (id) => (dispatch) => {
  dbVendor
    .doc(`/users/${id}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log(doc.id, "=>", doc.data());
        dispatch({
          type: GET_SINGLE_VENDOR,
          payload: { id: doc.id, ...doc.data() },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: LOADING_DATA, payload: {showloading: false, status: null} });

  let documents = [];
  dbPrice
    .collection("users")
    .get()
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        let document = { id: doc.id, ...doc.data() };
        return documents.push(document);
      });
    })
    .then(() => {
      dispatch({
        type: GET_USERS,
        payload: documents,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const getSingleAgent = (id) => (dispatch) => {
  dbAgent
    .doc(`/users/${id}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        // console.log(doc.id, "=>", doc.data());
        dispatch({
          type: GET_SINGLE_AGENT,
          payload: { id: doc.id, ...doc.data() },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

export const getSingleUser = (id) => (dispatch) => {
  dbPrice
    .doc(`/users/${id}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log(doc.id, "=>", doc.data());
        dispatch({
          type: GET_SINGLE_USER,
          payload: { id: doc.id, ...doc.data() },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

export const updateUser = (id, userData) => (dispatch) => {
  dbPrice
    .collection("users")
    .doc(id)
    .update({
      name: userData.name,
      email: userData.email,
      phone_number: userData.phone_number,
      credit: userData.credit,
      user_id: userData.user_id,
    })
    .then(() => {
      dispatch({
        type: USER_UPDATE,
        payload: "success",
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const createDispatcher = (credentials) => (dispatch) => {
  let userId = dbProduct.collection("dispatchers").doc().id;
  return productsStorage.ref().child(`dispatchers/profilePictures/${userId}/image.jpg`).put(credentials.image, {contentType: credentials.image.type}).then((uploadResult) => {
    return uploadResult.ref.getDownloadURL();
  }).then((url) => {
    return dbProduct.collection("dispatchers").doc(userId).set({
      user_name: credentials.name,
      user_email: credentials.email,
      user_phone_number: credentials.phone,
      user_avatar: url,
      creation_date: credentials.createdAt,
      market: credentials.market.name,
      market_id: credentials.market.market_id,
      status: "active",
      vehicle_license: credentials.license,
      rating: 5,
      total_deliveries: 0,
      user_id: userId
    })
  }).then(() => {
    dispatch({
      type: CREATE_DISPATCHER,
      payload: credentials,
    });
  }).catch((err) => {
    dispatch({
      type: CREATE_DISPATCHER_ERROR,
      payload: err.message,
    });
  });
};

export const getOrders = (orderIds, reducerType) => (dispatch) => {
  let documents = [];
  dbProduct
    .collection("orders")
    .get()
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        if (!doc.exists) {
          console.log("No document");
        }
        let document = { id: doc.id, ...doc.data() };
        documents.push(document);
      });
      console.log(documents);
    })
    .then(() => {
      dispatch({
        type: GET_ALL_ORDERS,
        payload: documents,
      });
    }).catch(err=>{
      dispatch({
        type:SET_ERRORS,
        payload:err
      })
    })
};

export const fetchOrdersWithCursor = (orderIds, reducerType) => (dispatch) => {
  return new Promise((resolve, reject) => {
    //console.log("Order Ids to query:", orderIds, "Reducer:", reducerType);
    // Listen for order details.
    singletonCursorQuery = dbProduct
      .collection("orders")
      .where("order_id", "in", orderIds)
      .onSnapshot((snapshot) => {
        let orders = [];
        snapshot.docs.forEach((doc) => {
          //console.log("Fresh Order data:", doc.data());
          orders.push(doc.data());
        });
        //return resolve(orders);
        resolve(
          dispatch({
            type: reducerType,
            payload: orders,
          })
        );
      });
  });
};

export const clearCursorListener = () => {
  if (singletonCursorQuery) singletonCursorQuery();
};

export const getOrderMarketsAndVendors = (orderId) => (dispatch) => {
  let markets = [];
  let documents = [];
  dbProduct.collection("orders").doc(orderId).collection("markets").get().then(async (snapshots) => {
    snapshots.docs.map((doc) => markets.push(doc.id));
    for (let market of markets) {
      // Get vendors for each market.
      let vendorsList = [];
      let vendors = await dbProduct.collection("orders").doc(orderId).collection("markets").doc(market).collection("vendorOrders").get();
      vendors.docs.map((vendor) => vendorsList.push(vendor.id));
      documents.push({
        market: market,
        vendors: vendorsList
      });
    }
  }).then(() => {
    dispatch({
      type: GET_ORDER_MARKETS,
      payload: documents
    });
  })
}

function getVendorsForMarket(orderId, market) {
  return new Promise((resolve, reject) => {
    dbProduct.collection("orders").doc(orderId).collection("markets").doc(market).collection("vendorOrders")
  })
}

export const getVendorWithOrder = (id) => (dispatch) => {
  let documents = [];
  dbVendor
    .collection("users")
    .doc(id)
    .collection("orders")
    .get()
    .then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        console.log("vendor", doc);
        let document = { id: doc.id, ...doc.data() };
        documents.push(document);
        console.log(documents);
      });
    })
    .then(() => {
      dispatch({
        type: GET_VENDOR_WITH_ORDER,
        payload: documents,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const getVendorWithProduct = (id) => (dispatch) => {
  let documents = [];
  dbVendor
    .collection("users")
    .doc(id)
    .collection("products")
    .get()
    .then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        console.log("vendor", doc);
        let document = { id: doc.id, ...doc.data() };
        documents.push(document);
        console.log(documents);
      });
    })
    .then(() => {
      dispatch({
        type: GET_VENDOR_WITH_PRODUCT,
        payload: documents,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const getSingleOrders = (agentId, marketId, orderId) => (dispatch) => {
  return fetchVendorsForOrder(orderId, marketId)
    .then((vendorIds) => {
      //console.log("vendor Ids to Handle:", vendorIds);
      dispatch({
        type: GET_SINGLE_ORDER,
        payload: vendorIds,
      });
    })
    .catch((error) => {
      dispatch({ type: SET_ERRORS, payload: [] });
    });
};

function fetchVendorsForOrder(orderId, marketId) {
  return new Promise((resolve, reject) => {
    return dbProduct
      .collection("orders")
      .doc(orderId)
      .collection("markets")
      .doc(marketId)
      .collection("vendorOrders")
      .get()
      .then((querySnapshot) => {
        let vendorIds = [];
        querySnapshot.docs.forEach((doc) => {
          vendorIds.push(doc.id);
        });
        // console.log(vendorIds)
        return resolve(vendorIds);
      });
  });
}

export const getCustomer = (id) => (dispatch) => {
  dbPrice
    .doc(`/users/${id}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        console.log(doc.id, "=>", doc.data());
        dispatch({
          type: GET_CUSTOMER,
          payload: { id: doc.id, ...doc.data() },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: {},
      });
    });
};

export const listenForProductUpdates = (orderId, vendorId, marketId) => (
  dispatch
) => {
  let products = {};
  let query = dbProduct
    .collection("orders")
    .doc(orderId)
    .collection("markets")
    .doc(marketId)
    .collection("vendorOrders")
    .doc(vendorId)
    .collection("productsOrdered")
    .onSnapshot((querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        products[doc.id] = doc.data();
      });
      dispatch({
        type: LISTEN_FOR_PRODUCT_UPDATES + vendorId,
        payload: products,
        id: vendorId,
      });
    });
  singletonProductsQuery.push(query);
};

export const clearProductUpdatesListener = () => {
  console.log("Clearing product updates...");
  if (singletonProductsQuery) {
    singletonProductsQuery.map((query) => query());
  }
};

export const unsubscribeDispatchersListener = () => {
  if (singletonDispatcherQuery) singletonDispatcherQuery();
};

export const getDispatcher = () => (dispatch) => {
  let documents = [];
  singletonDispatcherQuery = dbProduct.collection("dispatchers").onSnapshot((snapshot) => {
    snapshot.docs.map((doc) => documents.push(doc.data()));
    dispatch({
      type: GET_DISPATCHERS,
      payload: documents,
    });
  });
};

export const getSingleMarket = (id) => async (dispatch) => {
  dbProduct.collection("markets").doc(id).get().then((doc) => {
    dispatch({
      type: GET_SINGLE_MARKET,
      payload: doc.data(),
    });
  }).catch((err) => {
    console.log(err);
    dispatch({
      type: SET_ERRORS,
      payload: err,
    });
  });
};

export const postMarket = (data)=> dispatch =>{
  let marketId = data.name.toLowerCase().substring(0, 5) + parseInt(Math.random() * 100);
  return dbProduct.collection("markets").doc(marketId).set({
    name: data.name,
    location:data.location,
    description:data.description,
    coord: new geoPoint(Number(data.latitude), Number(data.longitude)),
    market_id: marketId,
    agent_id: data.agent.user_id,
    agent_phone_number: data.agent.user_phone_number,
    agent_name: data.agent.user_name,
    agent_image_url: data.agent.user_avatar
  }).then(()=>{
    return dbAgent.collection("users").doc(data.agent.user_id).update({market_id: marketId, market_name: data.name});
  }).then(() => {
    dispatch({
      type: ADD_NEW_MARKET,
      payload: true
    });
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err,
    })
  })
}

export const postProduct = (data)=> dispatch =>{
  dbProduct.collection("products").doc().set({
    name: data.name,
    category: data.category,
    caption: data.caption,
    class: data.class,
    unit_measurements:data.unit_measurements

  }).then(()=>{
    dispatch({
      type: ADD_NEW_PRODUCT,
      payload: true
    })
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err,
    })
  })
}

export const getPriceNPayProducts = () =>dispatch =>{
  let documents =[];

  dbProduct.collection("products").get()
  .then((querySnapshot) =>{
    querySnapshot.docs.forEach(doc =>{
      if(!doc.exist){
        console.log("No document")
      }
      let document = {id: doc.id, ...doc.data()}
      documents.push(document)
      console.log(documents);
    })
    
  }).then(()=>{
    dispatch({
      type: GET_PRICEPAY_PRODUCTS,
      payload: documents
    })
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err
    })
  })
}


export const getVerifyProducts = () =>dispatch =>{
  let documents =[];

  dbProduct.collection("products").get()
  .then((querySnapshot) =>{
    querySnapshot.docs.forEach(doc =>{
      if(!doc.exist){
        console.log("No document")
      }
      let document = {id: doc.id, ...doc.data()}
      documents.push(document)
      console.log(documents);
    })
    
  }).then(()=>{
    dispatch({
      type: GET_PRICEPAY_PRODUCTS,
      payload: documents
    })
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err
    })
  })
}
export const getSingleProduct = (id) => (dispatch) => {
  dbProduct
    .collection("products")
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.log("No doc");
      }

      dispatch({
        type: GET_SINGLE_PRODUCT,
        payload: { id: doc.id, ...doc.data() },
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const updateProduct = (id, data) => (dispatch) => {
  dbProduct
    .collection("products")
    .doc(id)
    .update({
      name: data.name,
      category: data.category,
      caption: data.caption,
      class: data.class,
      unit_measurements: data.unit_measurements
    })
    .then(() => {
      dispatch({
        type: UPDATE_PRODUCT,
        payload: "success",
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const deleteProduct = (id) => dispatch =>{
  let document = dbProduct.collection("products").doc(id);
  document.get().then(doc =>{
    if(!doc.exists){
      console.log("No Document")
    }
    document.delete()
  }).then(()=>{
      dispatch({
        type: DELETE_PRODUCT,
        payload: "success"
      })  
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload: err
    })
  })
}


export const updateMarketAgent = (market_id, market_name, agent) => dispatch =>{
  dbAgent.collection("users").doc(agent.user_id).update({
    market_id: market_id,
    market_name: market_name
  }).then(()=>{
    return dbProduct.collection("markets").doc(market_id).update({
      agent_id: agent.user_id,
      agent_name: agent.user_name,
      agent_phone_number: agent.user_phone_number,
      agent_image_url: agent.user_avatar
    });

  }).then(()=>{
    dispatch({
      type: MARKET_AGENT_UPDATE,
      payload: agent
    })
  }).catch(err=>{
    dispatch({
      type:SET_ERRORS,
      payload: err
    })
  })
}


export const getSingleDispatcher = (id) => (dispatch) => {
  dbProduct
    .collection("dispatchers")
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.log("No doc");
      }

      dispatch({
        type: GET_SINGLE_DISPATCHER,
        payload: { id: doc.id, ...doc.data() },
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const getAllProductsForAVendor = (
  vendorId,
  orderId,
  marketId,
  agentId
) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let products = [];
    return dbProduct
      .collection("orders")
      .doc(orderId)
      .collection("markets")
      .doc(marketId)
      .collection("vendorOrders")
      .doc(vendorId)
      .collection("productsOrdered")
      .where("agent_id", "==", agentId)
      .get()
      .then((querySnapshot) => {
        // console.log("Product Snapshots:", querySnapshot.size);

        querySnapshot.docs.forEach((doc) => {
          let document = { id: doc.id, ...doc.data() };
          products.push(document);
        });
        return resolve(
          dispatch({
            type: GET_ALL_PRODUCTS,
            payload: products,
          })
        );
      });
  });
};

export const updateOrderStatus = async (
  status,
  orderId,
  marketId,
  vendorId,
  products
) => {
  let keys = Object.keys(products);
  for (let key of keys) {
    products[key].status = status;
    if (status === "agent_received_order") {
      products[key].agent_collection_timestamp = moment().valueOf();
    } else if (status === "order_enroute") {
      products[key].order_enroute_timestamp = moment().valueOf();
    } else if (status === "order_delivered") {
      products[key].delivery_timestamp = moment().valueOf();
    }

    await dbProduct
      .collection("orders")
      .doc(orderId)
      .collection("markets")
      .doc(marketId)
      .collection("vendorOrders")
      .doc(vendorId)
      .collection("productsOrdered")
      .doc(key)
      .update(products[key]);
  }
  //console.log("Updated Details:", products);
};

export const updateAgent = (id, data) => (dispatch) => {
  dbAgent
    .collection("users")
    .doc(id)
    .update({
      user_name: data.user_name,
      user_phone: data.user_phone,
      user_email: data.user_email,
      market: data.market,
      market_id: data.market_id,
      createdAt: data.createdAt,
      id: data.id,
      image_url: data.image_url,
      presently_status: data.presently_status,
      reassigned_orders: data.reassigned_orders,
      status: data.status,
      total_orders: data.total_orders,
    })
    .then(() => {
      dispatch({
        type: UPDATE_AGENT,
        payload: data,
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};



export const updateDispatcher = (id, data) => (dispatch) => {
  dbProduct
    .collection("dispatchers")
    .doc(id)
    .update({
    user_name: data.user_name,
    user_email: data.user_email,
    user_phone: data.user_phone,
    image_url: data.image_url,
    createdAt: data.createdAt,
    market: data.market,
    market_id: data.market_id,
    status: data.status,
    presently_status: data.presently_status,
    rating: data.rating,
    license: data.license
    })
    .then(() => {
      dispatch({
        type: UPDATE_DISPATCHER,
        payload: data,
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};


export const updateDispatcherStatus = (id, status) => (dispatch) => {
  dbProduct
    .collection("dispatchers")
    .doc(id)
    .update({
    status: status === 'active' ? "inactive" : "active"
    })
    .then(() => {
      dispatch({
        type: STATUS_CHANGE,
        payload: true,
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};


export const agentWithOrders = (id) => dispatch =>{
  let documents = [];
  dbAgent.collection("users").doc(id).collection("orders").get()
  .then(querySnapshot =>{
    console.log(querySnapshot)
    querySnapshot.docs.forEach(doc =>{
      let document = {id:doc.id, ...doc.data()}
      documents.push(document)
    })
    console.log(documents)
  }).then(()=>{
    dispatch({
      type: AGENT_WITH_ORDERS,
      payload: documents
    })
  }).catch(err =>{
    dispatch({
      type: SET_ERRORS,
      payload:err
    })
  })
}

export const updateOrderDetails = async (
  dispatcher,
  orderId,
  marketId,
  vendorId,
  products
) => {
  let keys = Object.keys(products);
  for (let key of keys) {
    products[key].dispatcher_name = dispatcher.user_name;
    products[key].dispatcher_phone_number = dispatcher.user_phone;
    products[key].status = "dispatcher_received_order";
    products[key].agent_dispatch_timestamp = moment().valueOf();
    await dbProduct
      .collection("orders")
      .doc(orderId)
      .collection("markets")
      .doc(marketId)
      .collection("vendorOrders")
      .doc(vendorId)
      .collection("productsOrdered")
      .doc(key)
      .update(products[key]);
  }
  //console.log("Updated Details:", products);
};


export const deleteAgent = (id) => (dispatch) => {
  const document = dbAgent.doc(`/users/${id}`);
  document
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.log("No document");
      }
      document.delete();
    })
    .then(() => {
      dispatch({
        type: DELETE_AGENT,
        payload: id,
      });

    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.message,
      });
    });
};

export const deleteRider = (id) => (dispatch) => {
  const document = dbProduct.doc(`/dispatchers/${id}`);
  document
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.log("No document");
      }
      document.delete();
    })
    .then(() => {
      dispatch({
        type: DELETE_DISPATCHER,
        payload: id,
      });

    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.message,
      });
    });
};


export const deleteVendor = (id) => (dispatch) => {
  const document = dbVendor.doc(`/users/${id}`);
  document
    .get()
    .then((doc) => {
      if (!doc.exists) {
        console.log("No document");
      }
      document.delete();
    })
    .then(() => {
      dispatch({
        type: DELETE_VENDOR,
        payload: id,
      });

    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.message,
      });
    });
};


export const updateVendorStatus = (id) => (dispatch) => {
  dbVendor
    .collection("users")
    .doc(id)
    .update({
    status: "inactive"
    })
    .then(() => {
      dispatch({
        type: STATUS_CHANGE,
        payload: true,
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};



export const updateAgentStatus = (id) => (dispatch) => {
  dbAgent
    .collection("users")
    .doc(id)
    .update({
    status: "inactive"
    })
    .then(() => {
      dispatch({
        type: STATUS_CHANGE,
        payload: true,
      });
    })
    .then((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const getPendingVerifications = () => dispatch => {
  unsubscribePendingVerifications = dbProduct.collection("pendingVerification").onSnapshot((snapshot) => {
    let documents = [];
    snapshot.docs.map((doc) => documents.push({item_id: doc.id, ...doc.data()}));
    dispatch({
      type: GET_PENDING_VERIFICATIONS,
      payload: documents
    })
  })
}

export const unsubscribePendingVerificationsListener = () => {
  if (unsubscribePendingVerifications) unsubscribePendingVerifications();
}

export const getPendingProduct = (id) => dispatch => {
  dbProduct.collection("pendingVerification").doc(id).get().then((doc) => {
    dispatch({
      type: GET_PENDING_PRODUCT,
      payload: doc.data()
    });
  })
}

export const deletePendingProduct = (id, data) => dispatch => {
  let vendorDelete = dbVendor.collection("users").doc(data.vendor_id).collection("products").doc(data.product_id).delete();
  let productVendorDelete = dbProduct.collection("products").doc(data.product_id).collection("vendors").doc(data.vendor_id).delete();
  let originDelete = dbProduct.collection("pendingVerification").doc(id).delete();
  return Promise.all([vendorDelete, productVendorDelete, originDelete]);
}

let fieldVlue = totalFirebaseApp.fieldValue;

export const verifyPendingProduct = (id, data) => dispatch => {
  console.log("DATA:::", data);
  let vendorUpdate = dbVendor.collection("users").doc(data.vendor_id).collection("products").doc(data.product_id).update({
    status: "verified"
  });
  let productVendorUpdate = dbProduct.collection("products").doc(data.product_id).collection("vendors").doc(data.vendor_id).update({
    status: "verified"
  });
  let productUpdate = dbProduct.collection("products").doc(data.product_id).update({
    brands: fieldVlue.arrayUnion(data.new_brand)
  });
  return Promise.all([vendorUpdate, productVendorUpdate, productUpdate]).then(() => {
    return dbProduct.collection("pendingVerification").doc(id).delete();
  })
}

export const getPendingDisbursements = () => dispatch => {
  let documents = [];
  unsubscribePendingDisbursement = dbProduct.collection("pendingDisbursement").onSnapshot((snapshot) => {
    snapshot.docs.map((doc) => documents.push(doc.data()));
    dispatch({
      type: GET_PENDING_DISBURSEMENT,
      payload: documents
    })
  })
}

export const unsubscribeDisbursementListener = () => {
  if (unsubscribePendingDisbursement) unsubscribePendingDisbursement();
}

export const getDisbursementHistory = () => dispatch => {
  let documents = [];
  db.collection("disbursementHistory").onSnapshot((snapshot) => {
    snapshot.docs.map((doc) => documents.push(doc.data()));
    dispatch({
      type: GET_DISBURSEMENT_HISTORY,
      payload: documents
    })
  })
}

const adminAuth = totalFirebaseApp.adminAuth;

export const makeBulkPayment = (data) => dispatch => {
  dispatch({
    type: BULK_PAYMENT,
    payload: "loading"
  });
  let authCredential = totalFirebaseApp.adminAuthProvider.credential(adminAuth.currentUser.email, data.password);
  adminAuth.currentUser.reauthenticateWithCredential(authCredential).then((result) => {
    dispatch({
      type: BULK_PAYMENT,
      payload: "makingPayment"
    });
    let key = db.collection("makePayments").doc().id;
    db.collection("makePayments").doc(key).set({action: "start", status: "processing"});
    db.collection("makePayments").doc(key).onSnapshot((snapshot) => {
      if (snapshot.exists && snapshot.data().status === "completed") {
        dispatch({
          type: BULK_PAYMENT,
          payload: "completed"
        });
      }
    })
  }).catch((error) => {
    dispatch({
      type: BULK_PAYMENT,
      payload: null,
      message: error.message
    });
  })
}

export const unsubscribePaymentStatusListener = () => {

}