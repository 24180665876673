

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { getUsers } from 'Reducers/Actions/loadingData'
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from "@material-ui/core/TablePagination";


const useStyles = makeStyles(styles);




export default function UsersTable(props){
 const classes = useStyles();

 const dispatch = useDispatch()
 const {tableHeaderColor} = props;
 const {...rest } = props;

 const users = useSelector(state => state.dataState.users)
 const [load, setLoad] = useState(false)
 console.log(users)
 const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

const loadData = ()=>{
    dispatch(getUsers())
    setLoad(
      true
    )
  }

 useEffect(() => {
   loadData()
 }, [])

  

  const handleClick = (id) =>{
    console.log("you click me")
         return props.history.push(`/app/users/${id}`)
       
       }
 
  if(load){
  return (
    <div className={classes.tableResponsive}>
      <Card>
        <CardHeader color="primary"> <h4>Users List </h4></CardHeader>
   
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.primaryTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            ID
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                             Email
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Phone
                  
                  </TableCell>
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {users && users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
            return (
              <TableRow key={user.id} className={classes.tableBodyRow} onClick={(e)=> handleClick(user.id)}>
              
                    <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell align="left">{user.name}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="left">{user.phone_number}</TableCell>

                  
               
              </TableRow>
            );
          })}

<TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableBody>
      </Table>
      </Card>
    </div>
  );
 } else{
          return(
            <div>
              Loading...
            </div>
          )
        }
};








UsersTable.defaultProps = {
  tableHeaderColor: "gray"
};

UsersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};





