

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Modal from "views/pages/modal";
// import CreateVendor from "./addVendor";
import Button from "components/CustomButtons/Button.js";
import {getVendors} from "Reducers/Actions/loadingData"
import TablePagination from "@material-ui/core/TablePagination";
import {useSelector, useDispatch} from "react-redux"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);


export default function VendorsTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vendors= useSelector(state => state.dataState.vendors)
 
  const handleClick = (id) =>{
    console.log("you click me")
    return props.history.push(`/app/vendors/${id}`)
  
}


const [show, setShow] = useState(false)

const showModal = () => {
  setShow(true)
}

const hideModal = () =>{
  setShow(false)
}


const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


useEffect(() => {
  dispatch(getVendors())

}, [])

  const {tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
        <Card>
            <CardHeader color="primary"> <h4>Vendors</h4></CardHeader>
       
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.primaryTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Vendor Id
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                          Market
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Phone
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            
                      Total Earnings
                  </TableCell>
                  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {vendors && vendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow key={row.user_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(row.user_id)}>
            
                    <TableCell className={classes.tableCell} >
                      {row.user_id}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.market_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.phone_number}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.total_earnings}
                    </TableCell>
            
              </TableRow>
            );
          })}

              <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={vendors.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableBody>
      </Table>
       
      
      </Card>
      {/* <Modal show={show} handleClose={hideModal}>
          <CreateDispatcher handleClose={hideModal} />
        </Modal> */}
        {/* <div>
      <Button color="warning"  onClick={showModal}> Add Vendor </Button>
      </div> */}
    </div>
  );
}

VendorsTable.defaultProps = {
  tableHeaderColor: "gray"
};

VendorsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


