import React, {useEffect, useState, Component} from "react";
import { useSelector, useDispatch,  } from 'react-redux';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import Product from "./update"
import Modal from "views/pages/modal";
import { white } from "material-ui/styles/colors";
import {getPendingProduct, deletePendingProduct, verifyPendingProduct} from "Reducers/Actions/loadingData";
import moment from "moment";

import avatar from "assets/img/faces/fruit.jpeg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


const useStyles = makeStyles(styles);

export default function ProductSingle(props){
  const id = props.match.params.id;
  const classes = useStyles();
  const dispatch = useDispatch()
  const data =  useSelector(state => state.dataState.pendingProduct);
//   const agentOrders = useSelector(state =>state.dataState.agentOrders)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false);
 

  const handleClose = (value) => {
    setOpen(false)
    // setSelectedValue(value)
  
   }; 
  
   const handleClickOpen = () => {
    setOpen(true);
  };


 useEffect(() => {
    let id= props.match.params.id;
  if (id) {
    dispatch(getPendingProduct(id)); 
  }
  
 }, [id])

 const deleteHandle = () => {
  let info = prompt(
    "You are about to delete this product specification created by a vendor, type yes if you wish to proceed", "yes"
  );
  if (info === "yes") {
    dispatch(deletePendingProduct(id, data));
    props.history.goBack();
  }
};

const verifyProduct = () => {
  dispatch(verifyPendingProduct(id, data));
  props.history.goBack();
}


  const { ...rest } = props
  if(data){
  return (
    <div>
      <Header
        absolute
        color="black"
        brand="Price n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer style={{marginTop:"100px"}} justify={"center"}>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            {/* <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Product</h4>
              <p className={classes.cardCategoryWhite}>Product Details</p>
            </CardHeader> */}
             <CardAvatar profile>
              <a href="#" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <GridContainer>
        
              </GridContainer>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                   <GridItem xs={12} sm={12} md={4}>
                   Product Id
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{data.product_id}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   Vendor Id
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{data.vendor_id}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   Product Name
               <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{data.product_name}</p>
                   </GridItem>
                  </GridContainer>
                  <GridContainer>
                   <GridItem xs={12} sm={12} md={4}>
                   New Brand
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{data.new_brand}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   Creation Date
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{moment(data.timestamp).format('LL')}</p>
                   </GridItem>
                   <GridItem xs={12} sm={12} md={4}>
                   Product Info
                    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{data.info}</p>
                   </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={6} md={4} style={{margin: "auto"}}>
                <Button color="danger" onClick={deleteHandle}>Delete Product</Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                <Button color="primary" onClick={() => verifyProduct()}>Verify Product</Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                </GridItem>
              </GridContainer>
            </CardBody>
            <Product onClose={handleClose} open={open} id={id} data={data}/>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
      );
    }else{
      return(
      <div>
        Loading....
      </div>
      )
    }
  
    }



