
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
// import Profile from './UserProfile';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


// const useStyles = makeStyles(styles);
class Payment extends Component {
  
    constructor(props){
        super(props)
     }
  
    render(){
    const {classes, ...rest }= this.props;
    return (
        <div>
        <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
          <GridContainer justify={"center"}
      style={{
        marginTop:"100px"
      }}>
        
        <GridItem xs={12} sm={12} md={8}>
          <Card >
            <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>DISBURSEMENT TO ROSICKY JAMES</h4>
            <p className={classes.cardCategoryWhite}> Pay,emt Details...</p>
            </CardHeader>
            <CardBody>
             <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
              <h4 className={classes.cardTitle} style={{color:"#ff4c00"}}>DISBURSEMENT ID: 10135678</h4>
              <p> Price 'n' Pay</p>
              </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>VENDOR ID </h5>
              <h6 className={classes.cardTitle}>76895453</h6>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>VENDOR NAME </h5>
              <h6 className={classes.cardTitle}>Rosicky James</h6>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> VENDOR Phone No </h5>
              <h6 className={classes.cardTitle}>0806754563</h6>
              </GridItem>
              </GridContainer>
              <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Total Amount</h5>
              <h6 className={classes.cardTitle}>456000</h6>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}>Amount Payable </h5>
              <h6 className={classes.cardTitle}>5000</h6>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500px"}}> Commission </h5>
              <h6 className={classes.cardTitle}>450000</h6>
              </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3} style={{margin: "auto"}}>
                <Button color="info" onClick={(e)=> this.handleSubmit(e)} round>Pay 45,6000</Button>
                </GridItem>
                
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
        </div>
    )
                  }
}








export default withStyles(styles)(Payment)
