import React, {useState, useEffect}from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";

import NewProduct from "./postProduct";
import GridItem from "components/Grid/GridItem.js";

import {useSelector, useDispatch} from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";

import {getPriceNPayProducts} from "Reducers/Actions/loadingData";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
const useStyles = makeStyles(styles);

export default function ProductTable(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const pricenpayProduct = useSelector(state => state.dataState.pricenpayProduct)
  const showModal = () => {
    setShow(true)
  }

  useEffect(() => {
    dispatch(getPriceNPayProducts())
   
  }, [])
  
  //console.log(pricenpayProduct)
  const handleClose = (value) => {
    setOpen(false)
    // setSelectedValue(value)
  
   }; 
  
   const handleClickOpen = () => {
    setOpen(true);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) =>{
    return props.history.push(`/app/products/${id}`) 
}
  return (
    <div className={classes.tableResponsive}>
        <Card>

        <CardHeader color="warning">
          <GridContainer>
            <GridItem xs={12} sm={6} md={9}>
            <h4>Products Lists</h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>  
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height:"100%"}}>
                <Button color="white" onClick={handleClickOpen } style={{color: "#FE5E00"}}>Add Product</Button>
              </div>
        {/* <Button color="warning"  onClick={handleClickOpen }>Add Product</Button> */}
          </GridItem>
          </GridContainer>
        </CardHeader>
        
        
            <Table className={classes.table}>
       
       {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
   
        <TableHead className={classes.warningTableHeader} > 
        
         <TableRow className={classes.tableHeadRow}>  
         <TableCell
                 className={classes.tableCell + " " + classes.tableHeadCell}>
                         Product Id
               
               </TableCell>
               <TableCell
                 className={classes.tableCell + " " + classes.tableHeadCell}>
                         Name
               
               </TableCell>
               <TableCell
                 className={classes.tableCell + " " + classes.tableHeadCell}>
                       Class
               
               </TableCell>
               {/* <TableCell
                 className={classes.tableCell + " " + classes.tableHeadCell}>
                         Caption
               </TableCell> */}
               <TableCell
                 className={classes.tableCell + " " + classes.tableHeadCell}>
                         Category
               </TableCell>
         </TableRow>
       </TableHead>
     <TableBody>
       {pricenpayProduct!=null && pricenpayProduct.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
         return (
           <TableRow key={product.id} className={classes.tableBodyRow} onClick={(e)=> handleClick(product.id)}>
                 <TableCell className={classes.tableCell} >
                   {product.id}
                 </TableCell>
                 <TableCell className={classes.tableCell} >
                   {product.name}
                 </TableCell>
                 <TableCell className={classes.tableCell} >
                   {product.class}
                 </TableCell>
                 {/* <TableCell className={classes.tableCell}>
                   {product.caption}
                 </TableCell> */}
                 <TableCell className={classes.tableCell}>
                   {product.category}
                 </TableCell>
         
           </TableRow>
         );
       })}

           <TablePagination
           rowsPerPageOptions={[5, 10, 25]}
           component="div"
           count={pricenpayProduct.length}
           rowsPerPage={rowsPerPage}
           page={page}
           onChangePage={handleChangePage}
           onChangeRowsPerPage={handleChangeRowsPerPage}
         />
     </TableBody>
   </Table>
      </Card>

      <NewProduct onClose={handleClose} open={open}/>
    </div>
  );
}

ProductTable.defaultProps = {
  tableHeaderColor: "gray"
};

ProductTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
