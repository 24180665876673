import {
  USER_ACCESS,
  SET_USER,
  DELETE_USER,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  SET_ERRORS,
  CLEAR_ERRORS,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  SIGN_OUT_USER,
} from "../Redux/type";

const initialState = {
  users: [],
  user: {},
  errors: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      console.log("success", action.payload);
      return {
        ...state,
        message: "Register successfully",
        loading: false,
        authenticated: true,
        // users: [action.payload, ...state.users]
      };

    case LOGIN_USER:
      console.log("LOGIN", action.payload);
      return {
        ...state,
        loading: false,
        authenticated: true,
      };
    case LOGIN_USER_ERROR:
      console.log("failed", action.payload);
      return {
        ...state,
        errors: action.payload,
        loading: false,
        message: action.payload,
        authenticated: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
        message: "",
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case REGISTER_USER_ERROR:
      console.log("FAILED to register ", action.payload);
      return {
        ...state,
        message: action.payload,
        authenticated: false,
        errors: action.payload,
        loading: false,
        users: [],
      };

    case USER_ACCESS:
      return {
        ...state,
        access: action.payload,
      };

    case SIGN_OUT_USER:
      return { ...state, loading: false, message: null, authenticated: false };

    case "PASSWORD_UPDATE":
      return { ...state, loading: action.payload };

    case "PASSWORD_UPDATE":
      return { ...state };

    default:
      return state;
  }
};

export default authReducer;
