
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";

// import Icon from '../images/icon.png';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";

import {postAccess} from "Reducers/Actions/authAction"

// import Profile from './UserProfile';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  propertyForm:{
    margin: "auto",
  }

};


const useStyles = makeStyles(styles);
export default function Disparcher(props){
  
    const classes = useStyles()
    
   
    const { ...rest }= props;

    const dispatch = useDispatch();
   
 


        const [access, setAccess]= useState("")



  const handleGeneration = ()=>{
      let access =  makeId(6);
      dispatch(postAccess(access))
      setAccess(access)
  }

  const makeId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
    

  return (
    <div>
      <Card>

        <CardBody profile>
          <GridContainer>

            <h4 className={classes.cardTitle}>GENERATE ACCESS TOKEN</h4>

            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" round onClick={handleGeneration}>
                Generate Token
                         </Button>
            </GridItem>
            <br/>
            <br/>
            <br/>
            <GridItem xs={12} sm={12} md={12}>
              TOKEN: <strong>{access}</strong>
            </GridItem>
          </GridContainer>

        </CardBody>
      </Card>
    </div>
  )
                  
}







//   return (
//     <div>
//     <Card>
           
//            <CardBody profile>
//         <GridContainer>
//         <GridItem xs={12} sm={12} md={6}>
        
//               <h4 className={classes.cardTitle}>ACCESS TOKEN</h4>
//               <CustomInput
//                       labelText={state.user_email}
//                       id="token"
//                       name="token"
//                       formControlProps={{
//                         fullWidth: true,
//                       }}
//                       inputProps={{
//                         disabled: false,
//                         type: "text",
//                         onChange: e =>setAccess( e.target.value ),
//                       }}
//                     />
//                     </GridItem>
//             <GridItem xs={12} sm={12} md={6}>
//               <Button color="primary" round onClick={handleGeneration}>
//                 Generate Access Token
//               </Button>
//               </GridItem>
//               </GridContainer>
//               <GridContainer>
//               <GridItem xs={12} sm={12} md={6}>
//                   {access}
//                   </GridItem>
//               </GridContainer>
//             </CardBody>
//           </Card>       
     
//     </div>
//   );
// }
