
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import rootReducer from './Reducers/Redux/index'
import totalFirebaseApp from './Config/config'
import thunk from 'redux-thunk'
import {reduxFirestore} from 'redux-firestore';


const middleware = [thunk];
  
const configureStore = (
    state = {authState: {}   
}
) =>{

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, state, composeEnhancers(applyMiddleware(...middleware)),
 
    // reduxFirestore(totalFirebaseApp)
    );
 
}


export default configureStore;