

import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";


// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import LinearProgress from "components/CustomLinearProgress/CustomLinearProgress"
import { connect } from 'react-redux';

import {registerAction} from '../../Reducers/Actions/authAction';


import {Link, Redirect} from 'react-router-dom';


import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";


class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      phone: '',
      accessToken: '',
      errors: {},
      loading: false

    }
  }


  // componentWillMount(){
  //     this.loadAnimate()
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI) {
      this.setState({ errors: nextProps.UI });
    }

  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      loading: true
    })
    const userData = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      password: this.state.password,
      accessToken: this.state.accessToken
    }

    this.props.registerAction(userData, this.props.history);
    // this.props.history.push('/admin/dashboard')



  }

  handleTime = () => {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000);
  }


  handleChange = (event) => {

    this.setState({
      [event.target.id]: event.target.value,

    })
  }

  render() {
    //console.log(this.state)
    const { classes, message } = this.props;
    const { loading, errors } = this.state;
    const { ...rest } = this.props;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Price n' Pay Admin Panel"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Sign Up</h4>
                      <div className={classes.socialLine}>

                      </div>
                    </CardHeader>
                    <p className={classes.divider}> Let's get Started!</p>
                    <CardBody>
                      <CustomInput
                        labelText="Full Name..."
                        id="name"
                        name="name"
                        helperText={errors.name}
                        error={errors.name ? true : false}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <People className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        value={this.state.name}
                      />
                      <CustomInput
                        labelText="Phone no."
                        id="phone"
                        name="phone"
                        helperText={errors.phone}
                        error={errors.phone ? true : false}

                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                phone
                              </Icon>
                            </InputAdornment>
                          )
                        }}
                        value={this.state.phone}
                      />
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        name="email"
                        helperText={errors.email}
                        error={errors.email ? true : false}

                        formControlProps={{
                          fullWidth: true,

                        }}
                        inputProps={{
                          type: "email",
                          onChange: this.handleChange,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        value={this.state.value}

                      />
                      <CustomInput
                        labelText="Password"
                        id="password"
                        name="password"
                        helperText={errors.password}
                        error={errors.password ? true : false}

                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          onChange: this.handleChange,

                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                        value={this.state.password}

                      />

                      <CustomInput
                        labelText="Confirm password"
                        id="confirmPassword"
                        name="confirmPassword"
                        helperText={errors.confirmPassword}
                        error={errors.confirmPassword ? true : false}

                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          onChange: this.handleChange,

                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                        value={this.state.confirmPassword}

                      />
                      <CustomInput
                        labelText="Access token"
                        id="accessToken"
                        name="accessToken"
                        helperText={errors.accessToken}
                        error={errors.accessToken ? true : false}

                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          onChange: this.handleChange,

                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                            </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                        value={this.state.accessToken}

                      />

                      <div>
                        {loading ? (<div>
                          <LinearProgress size={50} className={classes.progress} />
                        </div>
                        ) : null}

                        {message ? (<div justify={"center"} style={{ color: "red" }}>
                          <p color="danger" style={{ textAlign: "center" }}> {message}</p>
                          {this.handleTime()}</div>) : null
                        }
                      </div>
                    </CardBody>

                    <CardFooter className={classes.cardFooter}>
                      <Button simple color="primary" size="lg" onClick={(e) => { this.handleSubmit(e) }}>
                        SignUp
                      </Button>
                    </CardFooter>
                  </form>
                  <p color="info" style={{ textAlign: "center" }}> You already have account click ? <Link to={`/login`}>Click Here</Link></p>

                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}



SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
    userSignIn: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired
}



const mapStateToProps = (state)=>{
  console.log(state)
  
 return { 
  markets: state.dataState.markets,
  message: state.authState.message,
  loading:state.authState.loading,
  UI: state.authState.errors
 }
}



const mapDispatchToProps = (dispatch)=> {
  return {
     registerAction: (userData, history) => {
        dispatch( registerAction(userData, history))
      }
}

}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUp))




