import React, { Component, useEffect, useState, useCallback } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// import Icon from '../images/icon.png';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// import Modal from "../moda";
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
//import CreateDispatcher from "./dispatcher";

// core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import ProductScreen from "./productScreen";
import { formatMoney } from "utils";
// import {
//   clearProductUpdatesListener,
//   getDispatcher,
// } from "Reducers/Actions/loadinGData";
//import { listenForProductUpdates, clearDispatcherListener, updateOrderDetails, updateOrderStatus } from "Reducers/Actions/loadinGData";
import { getCustomer, getOrderMarketsAndVendors,  clearProductUpdatesListener, getAllProductsForAVendor } from "Reducers/Actions/loadingData";
import { white } from "material-ui/styles/colors";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles(styles => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
}));

export default function ({ orderId, customerId, deliveryOption, totalAmount, orderTimestamp, rest, props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.dataState.customer);
  const marketsAndVendors = useSelector((state) => state.dataState.orderMarkets);
  console.log("ORDER MARKET:", marketsAndVendors);

  useEffect(() => {
    dispatch(getCustomer(customerId));
    dispatch(getOrderMarketsAndVendors(orderId));
  }, [])

  return (
    <div style={{ padding: "50px"}}>
      <Header
        absolute
        color="black"
        brand="Price n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      {customer ? (
        <div style={{ marginTop: "100px", marginBottom: "50px" }}>
            <Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>
                      ORDER DETAILS
                    </h4>
                    <p className={classes.cardCategoryWhite}>
                      {" "}
                      {orderId}
                    </p>
                  </CardHeader>
                </GridItem>
              </GridContainer>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardCategory}
                      style={{ color: "#FF6347", fontWeight: "500" }}
                    >
                      {" "}
                      CUSTOMER DETAILS{" "}
                    </h4>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                      style={{ color: "#000000", fontWeight: "500" }}
                    >
                      Customer Id
                    </h4>
                    <p> {customer.user_id}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                      style={{ color: "#000000", fontWeight: "500" }}
                    >
                      {" "}
                      Customer name
                    </h4>
                    <p> {customer.name}</p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                      style={{ color: "#000000", fontWeight: "500" }}
                    >
                      Customer phone number
                    </h4>
                    <p> {customer.phone_number}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                    // style={{ color: "#000000", fontWeight: "500" }}
                    >
                      Total amount
                    </h4>
                    <p style={{ color: "#FF5E00" }}><b>{"₦"}{formatMoney(totalAmount)}</b></p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                    // style={{ color: "#000000", fontWeight: "500" }}
                    >
                      {" "}
                      Delivery option
                    </h4>
                    <p style={{ color: "#FF5E00" }}><b>{deliveryOption == 0 ? "No delivery" : "Delivery Included"}</b></p>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <h4
                      className={classes.cardTitle}
                    // style={{ color: "#000000", fontWeight: "500" }}
                    >
                      Order date
                    </h4>
                    <p style={{ color: "#FF5E00" }}><b>{moment(orderTimestamp, "x").format('LL')}</b></p>
                  </GridItem>
                </GridContainer>
              </CardBody>

            </Card>
          </div>
      ) : (<div>Loading customer details...</div>)}
      {marketsAndVendors ? (
        marketsAndVendors.map((item) => (
          <div className={classes.root} style={{marginBottom: "50px"}}>
            <Card>
              <CardHeader color={"warning"}>ORDER FROM MARKET: {item.market}</CardHeader>
              <CardBody>
                <GridContainer>
                  {item.vendors && item.vendors.map(vendorId => (
                    <GridItem xs={12} sm={12} md={6}>
                      <ProductScreen className={classes.card} orderId={orderId} marketId={item.market} vendorId={vendorId} deliveryOption={deliveryOption} />
                    </GridItem>
                  ))}
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        ))
      ) : (<div>Loading markets...</div>)}
    </div>
  );
}