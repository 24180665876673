import React, {useEffect, useState} from "react";
import {useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import {getSingleUser} from 'Reducers/Actions/loadingData';

import avatar from "assets/img/faces/marc.jpg";
import { Link } from "react-router-dom";



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function User(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [load, setLoad] = useState(false);
  const id = props.match.params.id;
  const user = useSelector(state => state.dataState.user)
 

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoad(true)
dispatch(getSingleUser(id) )
  })


  return (
    <div>
      <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer justify= {"center"} style={{
        margin: "100px"
      }}>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="info">
              <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
              <h4 className={classes.cardTitleWhite}>User: {user.name}</h4>
              <p className={classes.cardCategoryWhite}> Active</p>
                </GridItem> 
                <GridItem xs={12} sm={12} md={4}>
              <h4> Credit Balance: {user.credit}</h4>
                  </GridItem>
              </GridContainer>

            </CardHeader>
            <CardAvatar profile>
<a href="#pablo" onClick={e => e.preventDefault()}>
  <img src={user.image_url} alt={user.name} />
</a>
</CardAvatar>
            <CardBody justify={"center"} style={{marginLeft:"50px"}}>
            <GridContainer >
               <GridItem xs={12} sm={12} md={6}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500"}}>ID </h5>
    <p className={classes.cardTitle}  style={{color: "#ff4c00", fontWeight: "500px"}}>{user.user_id}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500"}}>Name </h5>
    <p  style={{color: "#ff4c00", fontWeight: "500px"}}>{user.name}</p>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500"}}> Email </h5>
          <p style={{color: "#ff4c00", fontWeight: "500px"}}>{user.email}</p>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
               <h5 className={classes.cardTitle} style={{color: "#000000", fontWeight: "500"}}> Phone No </h5>
          <p className={classes.cardTitle}  style={{color: "#ff4c00", fontWeight: "500px"}}>{user.phone_number}</p>
              </GridItem>
            </GridContainer>
                        </CardBody>
            {/* <CardFooter>
              <Button color="info"><Link to={`/app/user/update/${id}`} >Update Profile</Link></Button>
            </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>

      
    </div>
  );
}



























