/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore'
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import firebase from 'firebase/app';
// import jwtDecode from 'jwt-decode';
import { Provider } from "react-redux"
import { LOGIN_USER, SIGN_OUT_USER  } from './Reducers/Redux/type';



import configureStore from 'store';


// core components
import Admin from "layouts/Admin.js";

import AuthRoute from './Config/auth';
import RTL from "layouts/RTL.js";
import Signup from "views/pages/signup";
import Login from "views/pages/login"
import App from "./app";

import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();


const rrfConfig = {
  useFirebaseForProfile: true, userProfile: 'users',

  attachAuthIsReady: true
}


const store = configureStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  enableLogging: true,
  logErrors: true,
  createFirestoreInstance
}



const token = localStorage.getItem('RFToken');



try{
if(token){
    store.dispatch({type: LOGIN_USER}); 
}else{
  store.dispatch({type:SIGN_OUT_USER})
}

}catch (error){
  console.log(error)
}



  ReactDOM.render(
    <Provider store={store}>
      < ReactReduxFirebaseProvider {...rrfProps}>
  <Router history={hist}>
    <Switch>
      <AuthRoute path="/admin" component={Admin} />
      <Route path="/app" render={props => <App {...props} />} />
      <Route path="/signup" component = {Signup} />
      <Route path="/login" component  = {Login} />
      <Redirect from="/" to="/login" />
    </Switch>
  </Router>
  </ ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);






