import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import config from "../../../Config/config";
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window'

import { agentAction } from "../../../Reducers/Actions/regAgentAction";
import { getMarkets } from "../../../Reducers/Actions/loadingData";

import { Link, Redirect } from "react-router-dom";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";
import { LOADING_DATA } from "Reducers/Redux/type";

const storage = config.agentStorage;

class SignUpAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      image: "",
      name: "",
      market: this.props.market ? this.props.market[0] : "",
      market_id: "",
      phone: "",
      errors: {},
      //loading: false,
      success: false,
    };
  }

  componentDidMount() {
    // this.loadAnimate()
    this.props.updateFetching({showLoading: false, status: null});
    this.props.getMarkets();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI) {
      this.setState({ errors: nextProps.UI });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // this.setState({
    //   loading: true,
    // });
    this.props.updateFetching({showLoading: true, status: null});
    const userData = {
      email: this.state.email,
      name: this.state.name,
      phone: this.state.phone,
      password: this.state.password,
      image: this.state.image,
      market: this.state.market,
      market_id: this.state.market_id,
      createdAt: new Date().getTime(),
    };
    //console.log("Data to submit:", userData);

    this.props.agentAction(userData);
  };

  handleChange = (event) => {
    console.log("OnChange:", event);
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  getMarketNames = () => {
    let res = [];
    this.props.markets &&
      this.props.markets.map((market) => {
        res.push({value: market.name, label: market.name, ...market});
      });

    return res;
  };

  handleDrop = (event) => {
    //this.handleUpload(event);
    //console.log("Selected market:", event);

    this.setState({
      market: event,
    });
  };

  handleImage = (e) => {
    if (e.target.files[0]) {
      console.log("Image File:", e.target.files[0]);
      this.setState({
        image: e.target.files[0],
      });
    }
  };

  handleTime = () => {
    setTimeout(() => {
      this.setState({
        //loading: true,
        email: "",
        name: "",
        phone: "",
        password: "",
        image: "",
        market: "",
        market_id: "",
        success: false,
      });
    }, 1500);
  };

  // cleanSTate = ()=>{
  //   setTimeout(() => {
  //     this.props.message = {}
  //   }, 2000);

  // this.props.history.push(`/admin/dashboard`)
  // }

  render() {
    const { classes, message, handleClose, onClose, open, status, showLoading } = this.props;
    //const { loading } = this.state;
    console.log("Loading state:", showLoading);
    const { errors } = this.state;
    const { ...rest } = this.props;
    const invalid =
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.phone === "" ||
      this.state.market === "" ||
      this.state.image === "" ||
      (this.state.password === "" && this.state.password.length < 6) ||
      (this.state.confirmPassword !== this.state.password);
    return (
      <div>
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title"> Create A new Agent</DialogTitle>
          <div>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <CardHeader color="warning" className={classes.cardHeader}>
                <h4>Create New Agent</h4>
                <div className={classes.socialLine}></div>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Full Name..."
                      id="name"
                      helperText={errors.name}
                      error={errors.name ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone no."
                      id="phone"
                      helperText={errors.phone}
                      error={errors.phone ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        onChange: this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              phone
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.phone}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      name="email"
                      helperText={errors.email}
                      error={errors.email ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.value}
                    />
                  </GridItem>
                </GridContainer>
                <br/>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText=""
                      underLabelText="Upload agent's photo"
                      id="image"
                      helperText={errors.images}
                      error={errors.images ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "file",
                        onChange: this.handleImage,
                      }}
                      value={this.state.image}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <span>Select market for agent</span>
                    <Select
                      options={this.getMarketNames()}
                      onChange={(value) => this.handleDrop(value)}
                      value={this.state.market}
                      //styles={customStyles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: '#00BFFE',
                          primary: '#FE5E00',
                        },
                      })}
                      menuPosition="fixed"
                    />
                    {/* <CustomDropdown
                      noLiPadding
                      buttonText="Select Market For Agent"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      dropdownList={this.handleMarket()}
                      onClick={(event) => this.handleDrop(event)}
                      value={this.state.market}
                    /> */}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Password"
                      id="password"
                      name="password"
                      helperText={errors.password}
                      error={errors.password ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: this.handleChange,

                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                                  </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      value={this.state.password}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      helperText={errors.confirmPassword}
                      error={errors.confirmPassword ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: this.handleChange,

                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                                  </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                      value={this.state.confirmPassword}
                    />
                  </GridItem>
                </GridContainer>
                <div style={{ margin: "auto" }}>
                  {status ? (
                    <div
                      justify={"center"}
                      //style={{ color: status === 'success'? "primary" : "red" }}
                    >
                      <p color={status === 'success'? "primary" : "danger"} style={{ textAlign: "center" }}>
                        {" "}
                        {message}
                      </p>
                      {/* {this.handleTime()} */}
                    </div>
                  ) : null}
                </div>
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                {!showLoading ? (
                  (!status || status === 'error') ? (
                    <div>
                      <Button
                        simple
                        color="warning"
                        size="lg"
                        type="submit"
                        disabled={invalid}
                      >
                        Create Agent
                            </Button>
                    </div>
                  ) : (
                      <Button simple color="info" size="lg" type="submit" onClick={() => onClose()}>
                        CLOSE
                        {/* <Link to={`/admin/dashboard`}> Ok </Link> */}
                      </Button>
                    )
                ) : <div></div>}
                
                {/* 
            <Button type="button" onClick={handleClose}>
                    Close
                  </Button> */}
              </CardFooter>
            
            </form>

            {showLoading ? (
              <div>
                <LinearProgress size={50} className={classes.progress} />
              </div>
            ) : null}
          </div>
          {/* <div
          // className={classes.pageHeader}
          // style={{
          //   backgroundImage: "url(" + image + ")",
          //   backgroundSize: "cover",
          //   backgroundPosition: "top center",
          // }}
          >

            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card className={classes[this.state.cardAnimaton]}>
                    

                  </Card>

                </GridItem>
              </GridContainer>
            </div>
            <Footer whiteFont />
          </div> */}
        </Dialog>
      </div>
    );
  }
}

SignUpAgent.propTypes = {
  classes: PropTypes.object.isRequired,
  userSignIn: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log("Register Agent state:", state);

  return {
    markets: state.dataState.markets,
    message: state.dataState.message,
    showLoading: state.dataState.showLoading,
    status: state.dataState.status,
    UI: state.dataState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    agentAction: (userData) => {
      dispatch(agentAction(userData));
    },
    getMarkets: () => {
      dispatch(getMarkets());
    },
    updateFetching: (update) => {
      //dispatch(updateLoading(update))
      dispatch({type: LOADING_DATA, payload: update})
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpAgent));
