import React, { Component, useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getPriceNPayProducts, postProduct} from "Reducers/Actions/loadingData";
// import Profile from './UserProfile';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles(styles);
export default function AddProduct(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pricenpayProduct = useSelector(state => state.dataState.pricenpayProduct)
  const [message, setMessage] = useState(false);
const { onClose, open} = props
  const [state, setState] = useState({
    caption: "",
    category:"",
    class:"",
    name:"",
    unit_measurements:[]
  });


  const handleCat = () => {
    let res = [];
    pricenpayProduct &&
    pricenpayProduct.map((product) => {
      if(res.indexOf(product.category) === -1 ){
        res.push(product.category);
      }
      });
    

    return res;
  };

  const handleCls = () => {
    let res = [];
    pricenpayProduct &&
    pricenpayProduct.map((product) => {
      if(res.indexOf(product.class) === -1 ){
        res.push(product.class);
      }
      });
    return res;
  };

 const  handleDrop = (event) => {
   
    pricenpayProduct &&
    pricenpayProduct.map((product) => {
        if (event === product.category) {
          setState({
            ...state,
            category: product.category,
          });
        }
      });
  };

  const  handleDropClass = (event) => {
   
    pricenpayProduct &&
    pricenpayProduct.map((product) => {
        if (event === product.class) {
          setState({
            ...state,
            class: product.class,
          });
        }
      });
  };

  useEffect(() => {
    dispatch(getPriceNPayProducts())
  }, [])

  const handleSubmit = ()=>{
   const data = {
      name: state.name,
      category: state.category,
      caption: state.caption,
      class: state.class,
      unit_measurements: state.unit_measurements.split(",")
    }

    dispatch(postProduct(data))
    setMessage(true)
  }

  console.log(state);
  
  return(
      <div>
      <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title"> Create A new Product</DialogTitle>
      
        <GridContainer justify={"center"}>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>
                  ADD PRODUCT
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Please complete all necessary information
                </p>
              </CardHeader>
              <CardBody>
                {/* <form onSubmit={this.handleSubmit}> */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText= "Product name"
                      id="name"
                      name="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: false,
                        type: "text",
                        value: state.name,
                        onChange: e =>setState({ ...state, name: e.target.value }),
                      }}
                    />
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      Category
                    </GridItem>
                  <GridItem xs={12} sm={12} md={7}>   
                  <CustomDropdown
                            noLiPadding
                            buttonText={state.category?state.category:"Category"}
                            buttonProps={{
                              className: classes.navLink,
                              color: "transparent",
                            }}
                            dropdownList={handleCat()}
                            onClick={(event) => handleDrop(event)}
                            value={state.category}
                          />
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    Class
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                  <CustomDropdown
                            noLiPadding
                            buttonText={state.class?state.class:"Class"}
                            buttonProps={{
                              className: classes.navLink,
                              color: "transparent",
                            }}
                            dropdownList={handleCls()}
                            onClick={(event) => handleDropClass(event)}
                            value={state.class}
                          />
                  </GridItem>
                  </GridContainer>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Caption"
                      id="caption"
                      name="caption"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.caption,
                        onChange: e =>setState({ ...state, caption: e.target.value }),
                      }}
                    />
                  </GridItem>
                  </GridContainer>
                  <GridContainer>

                
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="seperate multiple units with comma"
                      id="unit_measurements"
                      name="unit_measurements"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.unit_measurements,
                        onChange: e =>setState({ ...state, unit_measurements: e.target.value }),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                </CardBody>
                <GridContainer>
                <GridItem xs={12} sm={12} md={6}>    
                    <Button color="info" onClick={(e) => handleSubmit(e)}>
                   {!message?"Add Product": "Added" } </Button>
         
                      
                    </GridItem>
                  </GridContainer>
                </Card>
                </GridItem>
                </GridContainer>
                </Dialog>

      </div>
  )

}