
import React, { Component, useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// import Icon from '../images/icon.png';
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// import Profile from './UserProfile';
import {getSingleDispatcher, updateDispatcher} from "Reducers/Actions/loadingData";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles(styles);
export default function EditDispatcher(props) {
  const classes = useStyles();
  const dispatcher =  useSelector(state => state.dataState.dispatcher);
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch(); 
  const{id,handleClose, onClose, open} = props;
  

  const [state, setState] = useState({
    user_name: dispatcher.user_name,
    user_email: dispatcher.user_email,
    user_phone: dispatcher.user_phone,
    image_url: dispatcher.image_url,
    createdAt: dispatcher.createdAt,
    market: dispatcher.market,
    market_id: dispatcher.market_id,
    status: dispatcher.status,
    presently_status: dispatcher.presently_status,
    rating: dispatcher.rating,
    license: dispatcher.license
  });

console.log(state);

  

 useEffect(() => {
   if(id){


    getSingleDispatcher(id);
    //  setState({
    //   user_name: dispatcher.user_name,
    //   user_email: dispatcher.user_email,
    //   user_phone: dispatcher.user_phone,
    //   image_url: dispatcher.image_url,
    //   createdAt: dispatcher.createdAt,
    //   market: dispatcher.market,
    //   market_id: dispatcher.market_id,
    //   status: dispatcher.status,
    //   presently_status: dispatcher.presently_status,
    //   rating: dispatcher.rating,
    //   license: dispatcher.license
    //  })
  
   }
 }, {})
  // console.log(agent);
  //   const [image, setImage] = useState(null);
  // const [url, setUrl] = useState("");



  const handleSubmit = (e) => {
    e.preventDefault();

    const details = {
        user_name: state.user_name,
        user_email: state.user_email,
        user_phone: state.user_phone,
        image_url: state.image_url,
        createdAt: state.createdAt,
        market: state.market,
        market_id: state.market_id,
        status: state.status,
        presently_status: state.presently_status,
        rating: state.rating,
        license: state.license
  };

    dispatch(updateDispatcher(id, details));
    setMessage(true)
  };

  console.log("image:", state);
  console.log(state);
    if(dispatcher){
    return (
      <div>
       <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title"> Update Dispatcher </DialogTitle>
        <GridContainer justify={"center"}>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>
                  UPDATE DISPATCHER
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Please complete all necessary information
                </p>
              </CardHeader>
              <CardBody>
                {/* <form onSubmit={this.handleSubmit}> */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={state.user_email}
                      id="user_email"
                      name="user_email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: false,
                        type: "text",
                        value: state.user_email,
                        onChange: e =>setState({ ...state, user_email: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText={state.user_name}
                      id="user_name"
                      name="user_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: false,
                        type: "text",
                        value: state.user_name,
                        onChange: e =>setState({ ...state, user_name: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Phone"
                      id="user_phone"
                      name="user_phone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.user_phone,
                        onChange: e => setState({ ...state, user_phone: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Total Order Handled"
                      id="total_order_handled"
                      name="total_order_handled"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        value: state.total_orders,
                        onChange: e =>setState({ ...state, total_order: e.target.value }),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Market"
                      id="market"
                      name="market"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.market,
                        onChange: e =>setState({ ...state, market: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="MarketID"
                      id="marketId"
                      name="marketId"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.market_id,
                        onChange: e =>setState({ ...state, market_id: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Presently Status"
                      id="presently_status"
                      name="presently_status"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.presently_status,
                        onChange: e => setState({ ...state, presently_status: e.target.value }),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Reassigned Orders"
                      id="reassigned"
                      name="reassigned"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.reassigned_orders,
                        onChange: e => setState({ ...state,  reassigned_orders: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Status"
                      id="status"
                      name="status"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.status,
                        onChange: e =>setState({ ...state,  status: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="License"
                      id="license"
                      name="license"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.license,
                        onChange: e =>setState({ ...state,  license: e.target.value }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="rating"
                      id="rating"
                      name="rating"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: state.rating,
                        onChange: e =>setState({ ...state,  rating: e.target.value }),
                      }}
                    />
                  </GridItem>
      
                </GridContainer>

                {/* </form> */}
              </CardBody>
              <CardFooter>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    
                  <Button color="warning" onClick={(e) => handleSubmit(e)}>
                 {!message?"Update Dispatcher": "Updated" } </Button>
       
    
                  </GridItem>
                </GridContainer>
              
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        </Dialog>
      </div>
    );
                    }else{
                      return <div>Loading...</div>
                    }

    
}

EditDispatcher.propTypes = {
  classes: PropTypes.object.isRequired,
  updateDispatcher: PropTypes.func.isRequired,
};
