import React, {useEffect, useState, Component} from "react";
import {useSelector, useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import {getSingleUser, updateUser} from 'Reducers/Actions/loadingData';

import avatar from "assets/img/faces/marc.jpg";

export default function Form({setState, handleSubmit, id,  handleButton, message, state, user, ...props}){
        const {...rest} = props
        const { classes} = props
        
        return(
            <div>
                <Header
        absolute
        color="black"
        brand="Price 'n' Pay"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer justify= {"center"} style={{
        marginTop: "100px"
      }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="info">
    <h4 className={classes.cardTitleWhite}>Editing {state.name }</h4>
              <p className={classes.cardCategoryWhite}>Complete your profile</p>
            </CardHeader>
 <GridContainer>
<GridItem xs={12} sm={12} md={6}>
  <CustomInput
   labelText={state.name}
    id="name"
    name="name"
    formControlProps={{
      fullWidth: true
    }}
   
    inputProps={{
        type: "text",
        onChange :e=> setState({ ...state, number: e.target.value })
      }}
      value={state.name}   
  />
</GridItem>
<GridItem xs={12} sm={12} md={6}>
<CustomInput
    labelText={state.email}
    id="email"
    name="email"
    formControlProps={{
      fullWidth: true
    }}
 
    inputProps={{
        type: "text",
      
        onChange :e=> setState({ ...state, email: e.target.value })
      }}
      value={state.email}  
  />
</GridItem>
</GridContainer>
<GridContainer>
<GridItem xs={12} sm={12} md={6}>
<CustomInput
    labelText={state.phone_number}
    id="phone_number"
    name="phone_number"
    formControlProps={{
      fullWidth: true
    }}
  
    inputProps={{
        type: "text",
      
        onChange :e=> setState({ ...state, phone_number: e.target.value })
      }}
      value={state.phone_number}
  />
</GridItem>

<GridItem xs={12} sm={12} md={4}>
  <CustomInput
    labelText={state.credit}
    id="credit"
    name="credit"
    formControlProps={{
      fullWidth: true
    }}
    inputProps={{
      type: "number",
    
      onChange :e=>setState({ ...state, credit: e.target.value }),
    }}
    value={state.credit}
  />
</GridItem>
</GridContainer>
<GridContainer>

<GridItem xs={12} sm={12} md={6}>
    {!message?
<Button color="info" onClick = {(e)=> handleSubmit(e) }> Update </Button>:
 <Link to={`/app/users/${id}`}><Button color="success" onClick={handleButton}>Ok </Button></Link>}

</GridItem>

</GridContainer>
</Card>
</GridItem>
</GridContainer>
            </div>
        )

}