

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Modal from "views/pages/modal";
import CreateDispatcher from "./addDispatcher";
import Button from "components/CustomButtons/Button.js";
import {getDispatcher, unsubscribeDispatchersListener} from "Reducers/Actions/loadingData"
import TablePagination from "@material-ui/core/TablePagination";
import {useSelector, useDispatch} from "react-redux"
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";


const useStyles = makeStyles(styles);


export default function DispachersTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dispatchers = useSelector(state => state.dataState.dispatchers)
  const [open, setOpen] = useState(false);
  const update = useSelector((state) => state.dataState.update);
 
  const handleClick = (id) =>{
    console.log("you click me")
    return props.history.push(`/app/dispatchers/${id}`)
  
}

const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleClose = (value) => {
    setOpen(false)
    // setSelectedValue(value)
  
   }; 
  
   const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


useEffect(() => {
  dispatch(getDispatcher())
  return () => unsubscribeDispatchersListener();
}, [update])

  const {tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
        <Card>

        <CardHeader color="primary">
          <GridContainer>
            <GridItem xs={12} sm={6} md={9}>
              <h4>Dispatchers List</h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>  
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height:"100%"}}>
                <Button color="white" onClick={handleClickOpen } style={{color: "#00BFFE"}}>Add Dispatcher</Button>
              </div>
          </GridItem>
          </GridContainer>
        </CardHeader>
      
      
      <Table className={classes.table}>
       
          {/* <TableHead className={classes[tableHeaderColor + "TableHeader"]}> */}
      
           <TableHead className={classes.primaryTableHeader} > 
           
            <TableRow className={classes.tableHeadRow}>  
            <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            ACCOUNT ID
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Name
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                          License No
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Phone
                  
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}>
                            Rating
                  
                  </TableCell>
                  
             
            </TableRow>
           
          </TableHead>
        
         
       
        <TableBody>
          {dispatchers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow key={row.user_id} className={classes.tableBodyRow} onClick={(e)=> handleClick(row.user_id)}>
            
                    <TableCell className={classes.tableCell} >
                      {row.user_id}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.user_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      {row.license}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.user_phone_number}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.rating}
                    </TableCell>
            
              </TableRow>
            );
          })}
          {(!dispatchers || (dispatchers && dispatchers.length === 0)) && (
            <div style={{display: "flex", justifyContent: "center", backgroundColor: "", width: "100%"}}>
              <p>{" "}</p>
              <p>No data available.</p>
            </div>
          )}

              <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dispatchers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </TableBody>
      </Table>
       
      
      </Card>
      <CreateDispatcher open={open} onClose={handleClose}  />  
    </div>
  );
}

DispachersTable.defaultProps = {
  tableHeaderColor: "gray"
};

DispachersTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};


