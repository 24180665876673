/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import PlaceIcon from '@material-ui/icons/Place';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import DescriptionIcon from '@material-ui/icons/Description';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PaymentIcon from '@material-ui/icons/Payment';
import LockIcon from '@material-ui/icons/Lock';
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard";
// import Markets from "views/Products/markets";
// import Typography from "views/Typography/Typography.js";

import Users from "views/pages/Users/users";
import Agents from "views/pages/Agents/agents";
import Dispatchers from "views/pages/Dispatchers/dispatchers";
import Vendors from "views/pages/Vendors/vendors";
import Orders from "views/pages/Orders/orders";
import Payments from "views/pages/Payments/payments.js";
import PaymentHistory from "views/pages/Payments/paymentHistory.js";
import Markets from "views/pages/Market/markets.js";
import Products from "views/Products/products";
import VerifyProduct from "views/Products/verifyProd";
import AccessGen from "views/pages/genAccessToken";

// import RTLPage from "views/RTLPage/RTLPage.js";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Users,
    layout: "/admin"
  },
  {
    path: "/agents",
    name: "Agents",
    rtlName: "قائمة الجدول",
    icon: SupervisorAccountIcon,
    component: Agents,
    layout: "/admin"
  },

  {
    path: "/dispatchers",
    name: "Dispatchers",
    rtlName: "قائمة الجدول",
    icon: DirectionsBikeIcon,
    component: Dispatchers,
    layout: "/admin"
  },
  {
    path: "/markets",
    name: "Markets",
    rtlName: "قائمة الجدول",
    icon: PlaceIcon,
    component: Markets,
    layout: "/admin"
  },

  {
    path: "/vendors",
    name: "Vendors",
    rtlName: "قائمة الجدول",
    icon: LocalMallIcon,
    component: Vendors,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "قائمة الجدول",
    icon: DescriptionIcon,
    component: Products,
    layout: "/admin"
  },
  {
  path: "/verify_product",
  name: "Verify Products",
  rtlName: "قائمة الجدول",
  icon: VerifiedUserIcon,
  component: VerifyProduct,
  layout: "/admin"
},

  {
    path: "/payment",
    name: "Disbursement",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PaymentIcon,
    component: Payments,
    layout: "/admin"
  },

  {
    path: "/disbursementHistory",
    name: "Disbursement History",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: PaymentHistory,
    layout: "/admin"
  },
  {
    path: "/accessGen",
    name: "Access",
    rtlName: "قائمة الجدول",
    icon: LockIcon,
    component: AccessGen,
    layout: "/admin"
  },
];

export default dashboardRoutes;
