
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { connect } from 'react-redux';

import {  CircularProgress } from '@material-ui/core';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import LinearProgress from "components/CustomLinearProgress/CustomLinearProgress"
import { loginAction } from '../../Reducers/Actions/loginAction';


import {Link, Redirect} from 'react-router-dom';


import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg3.jpg";


class LoginPage extends Component{
    constructor(props){
        super(props)
        this.state ={
            email:'',
            password:'',
            errors:{},
            loading:false,
        }
    }
   
    
    // componentWillMount(){
    //     this.loadAnimate()
    // }

    componentWillReceiveProps(nextProps){
        if(nextProps.UI){
            this.setState({errors: nextProps.UI});
        }
        
    }

    handleTime = ()=>{
      setTimeout(() => {
        this.setState({
          loading:false
        })

      }, 1000);
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.setState({
          loading: true
        })
        const userData ={
            email: this.state.email,
            password: this.state.password
        }
        if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.props.loginAction(userData.email, userData.password, this.props.history);
        } else{
          this.props.loginAction(userData.email, userData.password, this.props.history);
        }
        
    }

    handleChange = (event)=>{   
        this.setState({
            [event.target.id]: event.target.value
        })
    }
  
  render(){
      console.log(this.state)
  const {classes, message} = this.props;
  const {loading } = this.state;
  const { ...rest } = this.props;
  const {errors } = this.state;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Price n' Pay Admin Panel"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[this.state.cardAnimaton]}>
                <form className={classes.form} >
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                    <div className={classes.socialLine}>
                     
                    </div>
                  </CardHeader>
                  <p className={classes.divider}> Glad to have you back!</p>
                  <CardBody>
              
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      name="email"
                      helperText={errors.email}
                      error={errors.email ? true: false}
                      formControlProps={{
                        fullWidth: true,
                        
                      }}
                      inputProps={{
                        type: "email",
                        onChange : this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                     value={this.state.value}
                     
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      name="password"
                      helperText={errors.password}
                      error={errors.password ? true: false}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        onChange :this.handleChange,
                      
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                      value={this.state.password}
                      
                    />
                  </CardBody>
                  <div>
                   {loading? (<div>
                                <LinearProgress size={50} className={classes.progress} /> 
                    </div>
                           ) : null}

                    { message?   ( <div justify={"center"} style={{color: "red"}}>
                            <p color="danger" style={{textAlign:"center"}}> {message}</p>
                            { this.handleTime()}</div>): null
                      }
                  </div>
                  <CardFooter className={classes.cardFooter}>
             
                    <Button simple color="primary" size="lg" onClick={(e)=> {this.handleSubmit(e)}}>
                      Login In
                    </Button>
                  </CardFooter>  
                </form>
                
                <p color="info" style={{textAlign:"center"}}> You don't have account ? <Link to={`/signup`}>Click Here</Link></p>
                   
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
}



LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
    userSignIn: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,
    UI:PropTypes.object.isRequired
}





const mapStateToProps = (state)=>{
  console.log(state)
  
 return { 
  markets: state.dataState.markets,
  message: state.authState.message,
  loading:state.authState.loading,
  UI: state.authState.errors
 }
}



const mapDispatchToProps = (dispatch)=> {
  return {
    loginAction : (email, password, history) => {
        dispatch(loginAction (email, password, history))
      }
}

}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginPage))




