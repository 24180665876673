import React, { Component, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes, { element } from "prop-types";
// import Icon from '../images/icon.png';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/headerlinkRight";
import CustomerScreen from "./customerScreen";


import ProductScreen from "./productScreen"
// import Profile from './UserProfile';
import { getCustomer, listenForProductUpdates,  clearProductUpdatesListener, getAllProductsForAVendor } from "Reducers/Actions/loadingData";




const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  propertyForm: {
    margin: "auto",
  },
};

const useStyles = makeStyles((styles) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    padding: styles.spacing(2),
    color: styles.palette.text.secondary,
  }
}));

export default function Order(props) {

  const orderId = props.match.params.order_id;
  const customerId = props.match.params.customer_id;
  const totalAmount = props.match.params.total_amount;
  const deliveryOption = props.match.params.delivery_option;
  const orderTimestamp = props.match.params.order_timestamp;

  const { ...rest } = props;

  return (
    <>
      <CustomerScreen customerId={customerId} orderId={orderId} totalAmount={totalAmount} deliveryOption={deliveryOption} orderTimestamp={orderTimestamp} rest={rest}/>
    </>
  );
}


